import { useState } from "react";
import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";
import { AxiosError } from "axios";

import { Button, TextInput, ErrorText } from "@pongo";
import { PersonalDetailsWithAddressState } from "@schema";

import { ApiController } from "../../../service/ApiController";
import { Spinner } from "../../../components/Spinner/Spinner";
import { createRepresentativeValues } from "./utils";

const emailForm = z.object({
  email: z.string().email(),
});

interface FindUserFormProps {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  updateRepresentativeDetails: (
    values: PersonalDetailsWithAddressState,
  ) => void;
}

export const FindUserForm = ({
  isLoading,
  setIsLoading,
  updateRepresentativeDetails,
}: FindUserFormProps) => {
  const [error, setError] = useState<string>("");

  const findUserByEmail = async (email: string) => {
    setIsLoading(true);
    try {
      const user = await ApiController.findUserByEmail(email);
      const representativeValues = createRepresentativeValues(user);

      updateRepresentativeDetails(representativeValues);
      setError("");
    } catch (error) {
      if (error instanceof AxiosError && error.status === 404) {
        setError("User not found");
      } else if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("There was an error finding user by email");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col">
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values) => findUserByEmail(values.email)}
        validate={withZodSchema(emailForm)}
      >
        {({ handleSubmit, values, errors, touched, handleChange }) => {
          return (
            <>
              <div className="flex flex-col gap-[16px] md:flex-row md:flex-wrap">
                <TextInput
                  showIcon={true}
                  icon="Search"
                  className="md:w-[320px]"
                  name="email"
                  variant={error ? "error" : "enabled"}
                  label="Search by email"
                  onChange={(newVal) => {
                    handleChange(newVal);
                    setError("");
                  }}
                  value={values.email}
                />
                <Button
                  size="2xl"
                  variant="primary"
                  disabled={!values.email}
                  type="button"
                  onClick={() => handleSubmit()}
                  className="w-full md:w-[200px] h-[64px] md:mb-[0px] mb-[16px]"
                >
                  <span id="button-text">
                    {isLoading ? (
                      <span className="flex items-center">
                        <Spinner /> Processing...
                      </span>
                    ) : (
                      <div>Find user</div>
                    )}
                  </span>
                </Button>
              </div>
              {error && <ErrorText>{error}</ErrorText>}
              {errors.email && touched.email && (
                <ErrorText>{errors.email}</ErrorText>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
