import { z } from "zod";

import { booleanPlusNotSure } from "@shared/types";
import { requiredField } from "./helpers";

export const FundingSchema = z
  .object({
    savingsFromEmployment: z.boolean(),
    gifts: requiredField(z.enum(booleanPlusNotSure)),
    remortgage: z.boolean(),
    investmentPortfolio: z.boolean(),
    inheritance: z.boolean(),
    divorceSettlement: z.boolean(),
    pensionLumpSumWithdrawal: z.boolean(),
    saleOfShares: z.boolean(),
    directorsLoansDividends: z.boolean(),
    otherFunding: z.boolean(),
    otherFundingOption: z.string().optional(),
    otherFundingComments: z.string().optional(),
  })
  .refine(
    (data) => {
      const isNotValid = data.otherFunding === true && !data.otherFundingOption;
      return !isNotValid;
    },
    {
      message: "Please provide details",
      path: ["otherFundingOption"],
    },
  );
