import { v4 as uuidv4 } from "uuid";
import { Formik, useFormikContext } from "formik";
import { withZodSchema } from "formik-validator-zod";

import {
  BuyerPropertyDetailsState,
  GiftorSchema,
  GiftorSchemaState,
} from "@schema";

import { Modal } from "../../../Modal/Modal";
import { initialGiftorStateObj } from "../../../../store/initialDetails";
import { AddressInputs } from "../AddressLookup/AddressInputs";
import { GiftorForm } from "../PurchaseFunded/GiftorForm";

interface AddGiftorModalProps {
  setIsModalOpen: (newValue: boolean) => void;
  onCancel: () => void;
}

export const AddGiftorModal = ({
  setIsModalOpen,
  onCancel,
}: AddGiftorModalProps) => {
  const { values, setFieldValue } =
    useFormikContext<BuyerPropertyDetailsState>();

  const onSubmit = (newValues: GiftorSchemaState) => {
    const transformedGiftor = newValues;

    let updatedGiftors = [];

    if (values.giftors) {
      updatedGiftors = [...values.giftors, transformedGiftor];
    } else {
      updatedGiftors = [transformedGiftor];
    }

    setFieldValue("giftors", updatedGiftors);
    setIsModalOpen(false);
  };

  return (
    <Formik
      initialValues={{
        ...initialGiftorStateObj,
        personId: uuidv4(),
      }}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validate={withZodSchema(GiftorSchema)}
    >
      {(formik) => (
        <Modal
          title="Add giftor"
          cancelButtonFunction={onCancel}
          saveButtonFunction={() => formik.handleSubmit()}
        >
          <GiftorForm formik={formik} />

          <AddressInputs
            namespace="correspondenceAddress"
            addressTitle="Giftor's correspondence address"
            cardAddressTitle="Giftor's correspondence address"
          />
        </Modal>
      )}
    </Formik>
  );
};
