import { Typography } from "@pongo";

import { LAND_REGISTRY_URL } from "../../../constants";

interface PostcodeLinkProps {
  postcode: string;
}

export const PostcodeLink = ({ postcode }: PostcodeLinkProps) => {
  const copyToClipboardAndOpenLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();

    navigator.clipboard
      .writeText(postcode)
      .then(() => {
        console.log("Postcode copied to clipboard:", postcode);
        window.open(LAND_REGISTRY_URL, "_blank");
      })
      .catch((err) => console.error("Failed to copy postcode:", err));
  };

  return (
    <a
      href={LAND_REGISTRY_URL}
      onClick={copyToClipboardAndOpenLink}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center underline text-ui-black-50 mr-[8px]"
    >
      <Typography variant="sm">Search on the Land Registry</Typography>
      <IconExternalLink className="ml-[4px]" />
    </a>
  );
};

const IconExternalLink = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 64 64"
    className={className}
    fill="currentColor"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <path fill="none" d="M-896 0H384v800H-896z" />
    <path d="M36.026 20.058H14.934a2.99 2.99 0 0 0-2.989 2.989v25.964A2.99 2.99 0 0 0 14.934 52h26.024a2.99 2.99 0 0 0 2.989-2.989V28.058h3.999v21.948a5.999 5.999 0 0 1-5.995 5.995h-28.01a5.998 5.998 0 0 1-5.995-5.995V22.052a5.997 5.997 0 0 1 5.995-5.995h22.085v4.001Z" />
    <path d="M55.925 25.32H51.92V14.839L24.026 42.732 21.194 39.9l27.895-27.895H38.605V8h17.318l.002.001V25.32Z" />
  </svg>
);
