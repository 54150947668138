import { z } from "zod";
import validator from "validator";

export const AddressSchema = z.object({
  line_1: z
    .string()
    .min(2, { message: "Address must be at least 2 characters long" })
    .max(255, { message: "Address must be less than 255 characters long" }),
  line_2: z.string().nullish(),
  line_3: z.string().nullish(),
  post_town: z
    .string()
    .min(2, { message: "Town must be at least 2 characters long" })
    .max(255, { message: "Town must be less than 255 characters long" }),
  country: z
    .string()
    .min(2, { message: "Country must be at least 2 characters long" })
    .max(255, { message: "Country must be less than 255 characters long" }),
  postcode: z.string().refine((str) => validator.isPostalCode(str, "GB"), {
    message: "Invalid postcode",
  }),
  county: z.string().nullish(),
  uprn: z.string().nullish(),
  district: z.string().nullish(),
  building_number: z.string().nullish(),
  sub_building_name: z.string().nullish(),
  building_name: z.string().nullish(),
  premise: z.string().nullish(),
  organisation_name: z.string().nullish(),
  po_box: z.string().nullish(),
});
