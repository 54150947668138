import { Container } from "../../components/Container/Container";
import { ContactFormBanner } from "../components/ContactForm/ContactFormBanner";

import Typography from "@/foundation/Typography/Typography";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { useTabletMediaQuery } from "../../mediaQueries";
import { EstateAgentBanner } from "../components/EstateAgentBanner/EstateAgentBanner";

export const PrivacyPolicy = () => {
  const isTablet = useTabletMediaQuery();

  return (
    <>
      <Header theme="dark" />
      <div className="bg-[url('/src/assets/background-header-swoop.svg')] bg-cover bg-no-repeat bg-bottom pt-[96px] md:mb-[50px]">
        <Container maxWidth={1150}>
          <div className="flex flex-col items-start justify-center h-[150px] md:h-[250px] px-[24px] lg:px-0">
            <Typography
              className="font-display text-center text-brand-heavy-teal-100 mb-[16px]"
              type="h1"
              variant={isTablet ? "h1" : "h2"}
              weight="semi-bold"
            >
              Privacy Policy
            </Typography>
          </div>
        </Container>
      </div>

      <Container maxWidth={1150}>
        <div className="mb-[100px]">
          <div className="md:w-[60%] px-[24px] lg:px-0">
            <Typography
              className="text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="p"
              variant="base"
              weight="regular"
            >
              Welcome to the Privacy Policy of Propelr Limited (a company
              registered in England and Wales with the company number 14625731
              and its registered office at 71 Queen Victoria Street, London,
              United Kingdom, EC4V 4BE (“Propelr”, “we”, “us”, “our”)). We take
              privacy seriously and want to make it easy for you to understand
              what we do with your personal information. This Privacy Policy
              explains what information we collect, what we do with that
              information, who we share it with, and how long we keep it.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              Propelr recognises that your privacy is important to you and that
              it is your right to control your personal information. We
              recommend that you read this Privacy Policy in full to ensure that
              you understand how we handle your information.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              Overview
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              To make it easier for you to review those parts of the Privacy
              Policy which apply to you, we have divided it up into the
              following sections:
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>Section 1 – General</strong>: This Section applies to
              everyone who visits the Propelr Website, uses the Propelr
              Platform, or otherwise interacts with us. In addition to Section
              1, either Section 2 or Section 3 below may also apply.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>Section 2 – Individual</strong>: This Section applies if
              you are an individual who has registered as a consumer via the
              Propelr Platform.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>Section 3 – Propelr Partner:</strong> This Section applies
              if you are a solicitor, conveyancer, estate agent or other
              professional user who interacts with us.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              Please take the time to read Section 1 (General) and, depending on
              whether you are an Individual or a Propelr Partner, the Section
              which applies to you.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              If you have any questions or comments about this Privacy Policy,
              then don’t hesitate to contact us by email at{" "}
              <a href="mailto:help@propelr.com">help@propelr.co.uk</a>.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              In this Privacy Policy, the following words have the following
              meanings:
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Agreement&quot;</strong> means an agreement between
              Propelr and a Propelr Partner for the provision of Propelr
              Services.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Data Protection Laws&quot;</strong> means all data
              protection and privacy legislation, regulation and guidance
              applicable from time to time in force in the UK and the EU/EEA
              including (but not limited to) Regulation (EU) 2016/679 (GDPR) and
              the Retained Regulation (EU) 2016/679 (UK GDPR).
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Individual&quot;</strong> means an individual (i.e.,
              an individual property buyer or seller) who has registered via the
              Propelr Platform and set up a Propelr Individual Account and/or
              using Propelr Services.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Personal Information&quot;</strong> means
              information relating to an identified or identifiable individual.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Propelr Individual Account&quot;</strong> means an
              account created by an Individual via the Propelr Platform.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Propelr Partner&quot;</strong> means an estate
              agent, solicitor, conveyancer or other partner of Propelr which
              uses Propelr Services.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Propelr Partner Account&quot;</strong> means an
              account created by a Propelr Partner (or created by Propelr for
              the benefit of the Propelr Partner) to enable the Propelr Partner
              to access and use the Propelr Services.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Propelr Platform&quot;</strong> is any platform made
              available to you from time to time by us in respect of the Propelr
              Service and any of its content.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Propelr Service&quot;</strong> is the service
              offered by us in respect of facilitating residential conveyancing
              transactions, including use of the Platform where applicable.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              <strong>&quot;Propelr Website&quot;</strong> means Propelr’s
              website at https://www.propelr.co.uk, or such other URL as Propelr
              may from time to time designate.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              Section 1: General - Privacy for prospects and visitors
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              This section applies to everyone who visits, or otherwise
              interacts with, the Propelr Website and/or the Propelr Platform,
              or otherwise interacts with us.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px]"
              type="h2"
              variant="h4"
              weight="semi-bold"
            >
              What Personal Information does Propelr collect when you visit the
              Propelr Website or use the Propelr Platform?
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              Some cookies ensure that certain parts of the website work
              properly and that your user preferences remain known. By placing
              functional cookies, we make it easier for you to visit our
              website. This way, you do not need to repeatedly enter the same
              information when visiting our website and, for example, the items
              remain in your shopping cart until you have paid. We may place
              these cookies without your consent.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              When you visit the Propelr Website, or use the Propelr Platform,
              we may collect and process the following Personal Information:
            </Typography>

            <ul className="list-disc pl-[24px]">
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Your name
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Your email address and/or postal address
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Your telephone number
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Your IP address
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Your preferences in receiving marketing from us and your
                  communication preferences
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Any additional personal information you provide in a message
                  you send us
                </Typography>
              </li>
            </ul>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              We are only allowed to use your personal information where we have
              a legal basis to do so. We set out below how we use your personal
              information and our legal basis for doing so.
            </Typography>

            <div className="mt-[36px]">
              <div className="grid grid-cols-2 gap-[24px]">
                <Typography
                  className="font-display text-brand-heavy-teal-100 mb-[16px]"
                  type="h3"
                  variant="lg"
                  weight="semi-bold"
                >
                  Why we use your Personal Information
                </Typography>

                <Typography
                  className="font-display text-brand-heavy-teal-100 mb-[16px]"
                  type="h3"
                  variant="lg"
                  weight="semi-bold"
                >
                  Legal Basis for using Personal Information
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  To respond to queries that you raise regarding the Propelr
                  Website and the Propelr Services; To put in place our contract
                  with you.
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  To put in place and perform our contract with you or to take
                  steps at your request before entering into a contract.
                  <br />
                  <br />
                  Our legitimate interests of providing information about our
                  services to you and responding to your queries.
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Preventing and detecting fraud against you or us.
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Our legitimate interest of minimising fraud that could be
                  damaging to you and/or us.
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Operational reasons such as improving efficiency, training,
                  quality control and customer service.
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Our legitimate interests, i.e. to be as efficient as we can so
                  that we can deliver the best service to you and our customer
                  and partners and to learn about how you use our services so
                  that we can identify improvements and enhancements.
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  To ensure our website is working correctly.
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Our legitimate interests of operating a website to provide
                  information and services.
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  To keep you updated about Propelr and our services and to
                  provide you with marketing materials.
                </Typography>

                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Depending on your circumstances:
                  <br />
                  <br />
                  If you are an individual who has used or is using our services
                  then we will do this for our legitimate interests of promoting
                  our business to existing and former customers;
                  <br />
                  <br />
                  If you have not used our services then we will not send you
                  marketing information or updates unless you have given us your
                  consent to receive that information (i.e. if you have
                  requested to receive updates and marketing information from
                  us).
                </Typography>
              </div>
            </div>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              If you are an Individual or Propelr Partner, we collect and
              process additional personal Information about you. Please see
              Section 2 or Section 3 of this Privacy Policy.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px]"
              type="h2"
              variant="h4"
              weight="semi-bold"
            >
              Who do we share your Personal Information with?
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              We may share your Personal Information with the following
              categories of recipients:
            </Typography>

            <ul className="list-disc pl-[24px]">
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  our third-party services providers or subcontractors
                  (including our independent consultants, data analytics
                  providers and cloud-based data processing and hosting
                  providers) who:
                </Typography>

                <ul className="list-disc pl-[24px]">
                  <li>
                    <Typography
                      className="text-brand-heavy-teal-100 mb-[16px]"
                      type="p"
                      variant="base"
                      weight="regular"
                    >
                      provide services to us (for example, to support the
                      delivery of, provide functionality on, or help to enhance
                      the security of the Propelr Website and the Propelr
                      Platform); or
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-brand-heavy-teal-100 mb-[16px]"
                      type="p"
                      variant="base"
                      weight="regular"
                    >
                      process Personal Information for content customisation,
                      personalisation, ad selection and ad delivery and
                      reporting, measurement purposes; or
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="text-brand-heavy-teal-100 mb-[16px]"
                      type="p"
                      variant="base"
                      weight="regular"
                    >
                      otherwise process Personal Information for purposes that
                      are described in this Privacy Policy or notified to you
                      when we collect your Personal Information.
                    </Typography>
                  </li>
                </ul>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  If we share Personal Information with our third-party services
                  providers or subcontractors, we will only do so insofar as it
                  is reasonably necessary for the purposes for which we have
                  collected it.
                </Typography>
              </li>

              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  to any competent law enforcement body, regulatory or
                  government agency, court or other third party where we believe
                  disclosure is necessary (i) as a matter of applicable law or
                  regulation, (ii) to exercise, establish or defend our legal
                  rights, or (iii) to protect your interests or those of any
                  other person.
                </Typography>
              </li>

              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  to an actual or potential buyer (and its agents and advisers)
                  in connection with any actual or proposed purchase, merger or
                  acquisition of any part of our business, provided that we
                  inform the buyer it must use your Personal Information only
                  for the purposes disclosed in this Privacy Policy.
                </Typography>
              </li>

              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  in order to enforce or defend our rights under an Agreement,
                  or to protect the rights, property, or safety of Propelr, our
                  customers, or others. This includes exchanging information
                  with other companies and organisations for the purpose of
                  fraud protection and credit risk reduction.
                </Typography>
              </li>

              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  If you are an Individual or Propelr Partner, we may share your
                  Personal Information with other third parties. Please refer to
                  Sections 2 and 3 of this Privacy Policy.
                </Typography>
              </li>
            </ul>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px]"
              type="h2"
              variant="h4"
              weight="semi-bold"
            >
              How do we keep your Personal Information secure?
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              We will take appropriate technical and organisational measures to
              ensure a level of security appropriate to the risk that could be
              encountered via the use of the Propelr Website, the Propelr
              Platform and/or the Propelr Service, taking into account the
              likelihood and severity those risks might pose to your rights and
              freedoms.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              In particular, we will take precautions to protect against the
              accidental or unlawful destruction, loss or alteration, or
              unauthorised disclosure of or access to the Personal Information
              transmitted, stored or otherwise processed by us. Please be aware
              that, while we make the security of the Propelr Website, the
              Propelr Platform, the Propelr Service and your Personal
              Information a high priority and devote considerable time and
              resources to maintaining robust IT security, no security system
              can prevent all security breaches. When you choose to share your
              Personal Information with us, you accept the aforesaid and provide
              your information at your own risk.
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px]"
              type="h2"
              variant="h4"
              weight="semi-bold"
            >
              Your data protection rights
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              You have the following data protection rights:
            </Typography>

            <div>
              <ul className="list-disc pl-[24px]">
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Where we are processing your Personal Information on the
                    basis of consent, you have the right at any time to withdraw
                    that consent to our processing of your Personal Information.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    You have the right to be told what Personal Information we
                    hold about you on our database and how we process that data.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    You have the right to request that we provide you with a
                    copy (in a commonly used electronic format) of all the
                    Personal Information that we hold about you. You will not
                    have to pay a fee for a copy of this data. However, if your
                    request is clearly unfounded, repetitive or excessive, we
                    have the right to charge you a reasonable fee.
                    Alternatively, we may refuse to comply with your request in
                    these circumstances.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    You have the right to request that we correct any inaccurate
                    or incomplete Personal Information that we hold about you.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    You have the right to request that we irretrievably delete
                    all Personal Information that we hold about you (the
                    so-called “right to be forgotten”). Note that there are
                    limited circumstances in which we are legally entitled to
                    refuse to comply with this request under Data Protection
                    Laws.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    You have the right to request that we transmit all the
                    Personal Information that we hold about you (in a
                    structured, commonly used and machine-readable form) to
                    another organisation’s IT environment. Note that we are only
                    legally obliged to comply with this request if it is
                    technically feasible for us to do so.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    You have the right to opt-out of marketing communications we
                    send you at any time. You can exercise this right by
                    clicking on the “unsubscribe” or “opt-out” link in the
                    marketing e-mails we send you, or by replying to any
                    marketing text messages with the word “STOP”.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    You have the right to complain to a data protection
                    authority about our collection and use of your Personal
                    Information. For more information, please contact the UK
                    Information Commissioner’s Office at:
                    <a href="https://ico.org.uk/concerns">
                      https://ico.org.uk/concerns.
                    </a>
                  </Typography>
                </li>
              </ul>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                If you wish to exercise any of the rights set out in 1-7 above
                you can do so at any time by sending us an email at{" "}
                <a className="underline" href="mailto:help@propelr.co.uk">
                  help@propelr.co.uk
                </a>
                . We respond to all requests we receive from individuals wishing
                to exercise their data protection rights in accordance with Data
                Protection Laws.
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px]"
                type="h2"
                variant="h4"
                weight="semi-bold"
              >
                Changes to this Privacy Policy
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                We keep this Privacy Policy under regular review and may update
                it from time to time in response to changing legal, technical or
                business developments. This version was last updated in July
                2023. Please review our Privacy Policy periodically. Changes to
                our Privacy Policy are effective when they are posted on this
                page.
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                If we update our Privacy Policy and the amendments involve a
                material change to the way we collect, use, share, store or
                otherwise process your Personal Information, we will take
                appropriate measures to inform you, consistent with the
                significance of the changes we make.
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px]"
                type="h2"
                variant="h4"
                weight="semi-bold"
              >
                Data Protection Service
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                You can obtain further information about data protection and
                privacy laws by visiting the Information Commissioner’s website
                at:{" "}
                <a
                  className="underline"
                  href="https://ico.org.uk/your-data-matters"
                >
                  https://ico.org.uk/your-data-matters
                </a>
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
                type="h2"
                variant="h3"
                weight="semi-bold"
              >
                Section 2: Individuals - Privacy for consumers
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                This section describes what information is collected by Propelr
                from Individuals, how that information is used by Propelr, and
                for how long Propelr keeps the information.
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px]"
                type="h2"
                variant="h4"
                weight="semi-bold"
              >
                What Personal Information do we collect from Individuals?
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                When you set up your Propelr Individual Account we will ask you
                to provide some or all of the following Personal Information,
                depending on the type of transaction that your solicitor, estate
                agent or other adviser (i.e. the relevant Propelr Partner) has
                asked us to provide:
              </Typography>

              <ul className="list-disc pl-[24px]">
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Your full name
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Your email address
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Your username and password (which you create)
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Your postal address
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Your telephone number
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Information specific to your transaction (e.g. details of
                    the property you are buying or selling, mortgage and other
                    financial details)
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    employment information (e.g. employer, occupation, how long
                    you have worked there)
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    You have the right to complain to a data protection
                    authority about our collection and use of your Personal
                    Information. For more information, please contact the UK
                    Information Commissioner’s Office at:{" "}
                    <a href="https://ico.org.uk/concerns">
                      https://ico.org.uk/concerns.
                    </a>
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Contact details of additional third parties that you might
                    want to share your information with (e.g. your adviser’s
                    full name, your employer’s name, their addresses, email
                    addresses, telephone numbers)
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Photographic proof of identity (e.g. passport, driving
                    licence or identity card)
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Financial personal details (e.g. bank details, transaction
                    details, account balance information)
                  </Typography>
                </li>
              </ul>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                We may also collect and process further Personal Information
                that you provide in response to requests from your solicitor,
                estate agent or other adviser, including information relating to
                the transaction or other activities that you are involved with.
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px]"
                type="h2"
                variant="h4"
                weight="semi-bold"
              >
                What Personal Information do we collect from Individuals?
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                As above we can only use your Personal Information if we have a
                legal basis for doing so. In addition to the purposes set out in
                Section 1, we will use an Individual’s Personal Information as
                set out below:
              </Typography>

              <div className="mt-[36px]">
                <div className="grid grid-cols-2 gap-[24px]">
                  <Typography
                    className="font-display text-brand-heavy-teal-100 mb-[16px]"
                    type="h3"
                    variant="lg"
                    weight="semi-bold"
                  >
                    Why we use your Personal Information
                  </Typography>

                  <Typography
                    className="font-display text-brand-heavy-teal-100 mb-[16px]"
                    type="h3"
                    variant="lg"
                    weight="semi-bold"
                  >
                    Legal Basis for using Personal Information
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    To create and maintain your Propelr Individual Account, and
                    to carry out the Propelr Service to you.
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    To put in place and perform our contract with you or to take
                    steps at your request before entering into a contractOur
                    legitimate interests of providing information about our
                    services to you and responding to your queries.
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Our legitimate interests of (i) minimising fraud that could
                    be damaging to you and/or us and (ii) providing an efficient
                    service to you
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    To conduct source of funds checks using open banking data
                    technology to access and analyse.
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    To put in place and perform our contract with you or to take
                    steps at your request before entering into a contract.
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Your financial and banking information, facilitated by
                    Propelr Partners.
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Our legitimate interests of (i) minimising fraud that could
                    be damaging to you and/or us and (ii) providing an efficient
                    service to you.
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    To enable you to share your personal information with any
                    third parties that you designate, e.g. if you instruct a
                    solicitor to write your will then you may choose to provide
                    the solicitor with access to your information via your
                    Propelr Individual Account.
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    To put in place and perform our contract with you or to take
                    steps at your request before entering into a contractOur
                    legitimate interests of providing information about our
                    services to you and responding to your queries and ensuring
                    the services provided are as efficient as possible.
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Our legitimate interest of minimising fraud that could be
                    damaging to you and/or us.
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Create aggregated and anonymised data (once anonymised the
                    data will not longer form part of your Personal
                    Information).
                  </Typography>

                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Our legitimate interest of compiling statistical and
                    property data to monitor patterns of transactions, carry out
                    market research and improve our products and services.
                  </Typography>
                </div>
              </div>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px]"
                type="h2"
                variant="h4"
                weight="semi-bold"
              >
                Who do we share Individuals’ Personal Information with?
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                We will share your Personal Information the relevant Propelr
                Partner, who is likely to be the solicitor, conveyancer, estate
                agent or other adviser who requested that you use the Propelr
                Services and/or Propelr Platform.
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                We may also share your Personal Information with third party
                service providers Propelr Services (including but not limited to
                identity verification checks and source of funds checks) for
                your solicitor, estate agent or other adviser. Where we share
                your Personal Information with third parties, we will only do so
                as far as it is reasonably necessary for the purposes described
                in this Privacy Policy.
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                If you decide to contact us via a social media platform such as
                Facebook, LinkedIn or Twitter then you should be aware that you
                will be sharing your personal data and any information contained
                in your message via that social media platform. We interact with
                social media messaging platforms for inbound enquiries only and
                do not proactively share personal information with them. We
                endeavour to respond to social media enquiries via our other
                channels, typically email or over the phone.
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px]"
                type="h2"
                variant="h4"
                weight="semi-bold"
              >
                Failure to provide Personal Information
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                Where we need to collect Personal Information by law, or under
                the terms of an Agreement, and you fail to provide that
                information when requested, we may not be able to provide the
                Propelr Service to you. In this case, we may have to cancel our
                Agreement with you and/or the Propelr Partner but we will notify
                you if this is the case at the time.
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px]"
                type="h2"
                variant="h4"
                weight="semi-bold"
              >
                International Transfers
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                Our servers are located in the European Union and the Personal
                Information that we collect directly from you will be stored on
                these servers. We may also transfer your Personal Information to
                one or more of our third-party service providers, some of which
                may be located outside of the EU, or operate from multiple
                locations (including non-EU locations). If your lawyer or other
                adviser is located outside the EU then we may also transfer your
                Personal Information to them.
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                Whenever we transfer your Personal Information out of the UK, we
                will ensure that it will be protected to a similar degree in
                that jurisdiction as within the UK and that the transfer meets
                one of the requirements set out in Data Protection Laws
                governing international transfers of data.
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                There are agreements in place to ensure that any international
                transfers of Personal Information to our third party service
                providers have appropriate safeguards that meet the requirements
                of Data Protection Laws.
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                Such appropriate safeguards may include standard data protection
                clauses adopted by a data protection regulator and approved by
                the European Commission, such as the European Commission’s
                standard contractual clauses.
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                To find out more about the standard contractual clauses, please
                visit:{" "}
                <a
                  className="underline"
                  href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                >
                  https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en
                </a>
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px]"
                type="h2"
                variant="h4"
                weight="semi-bold"
              >
                How long do we keep Individuals’ Personal Information?
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                We retain the information you provide to us through your Propelr
                Individual Account via the Propelr Platform for the purposes set
                out above for a period of 10 years.
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                We may create aggregated and anonymised data to use for our
                business purposes but this will not include your Personal
                Information.
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
                type="h2"
                variant="h3"
                weight="semi-bold"
              >
                Section 3: Partners - Privacy for estate agents, solicitors and
                other professionals
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                This section describes what information is collected by Propelr
                from Propelr Partners, how that information is used by Propelr,
                and for how long Propelr retains the information.
              </Typography>

              <Typography
                className="font-display text-brand-heavy-teal-100 mb-[16px]"
                type="h2"
                variant="h4"
                weight="semi-bold"
              >
                What Personal Information do we collect from Propelr Partners?
              </Typography>

              <Typography
                className="text-brand-heavy-teal-100 mb-[16px]"
                type="p"
                variant="base"
                weight="regular"
              >
                During the on-boarding process we may collect the following
                Personal Information:
              </Typography>

              <ul className="list-disc pl-[24px]">
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Names and contact details of Propelr Partner personnel who
                    will be using the Propelr Services.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Usernames and passwords.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Regulatory information of the Propelr Partner.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Your IP address
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="text-brand-heavy-teal-100 mb-[16px]"
                    type="p"
                    variant="base"
                    weight="regular"
                  >
                    Your communication preferences.
                  </Typography>
                </li>
              </ul>
            </div>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px] mt-[36px]"
              type="h2"
              variant="h3"
              weight="semi-bold"
            >
              Section 4: Third Party Services
            </Typography>

            <Typography
              className="font-display text-brand-heavy-teal-100 mb-[16px]"
              type="h2"
              variant="h4"
              weight="semi-bold"
            >
              Google reCAPTCHA
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              We use the reCAPTCHA service provided by Google Inc. (“Google”) to
              protect your submissions via internet submission forms on this
              site. This plugin checks if you are a person to prevent certain
              website functions from being abused by spam bots.
            </Typography>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              The reCAPTCHA plugin may collect information about your device and
              browser, such as:
            </Typography>

            <ul className="list-disc pl-[24px]">
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  IP address
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Referrer URL
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Date and time of your visit
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Browser settings
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Information about the operating system
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px]"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  Mouse movements and keyboard strokes
                </Typography>
              </li>
            </ul>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              This data is collected and sent to Google for analysis. The data
              collected during this process is used to improve reCAPTCHA and for
              general security purposes. The use of reCAPTCHA is subject to
              Google’s Privacy Policy and Terms of Service. You can review these
              at:
            </Typography>

            <ul className="list-disc pl-[24px]">
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px] underline"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  <a href="https://policies.google.com/privacy">
                    Google Privacy Policy
                  </a>
                </Typography>
              </li>
              <li>
                <Typography
                  className="text-brand-heavy-teal-100 mb-[16px] underline"
                  type="p"
                  variant="base"
                  weight="regular"
                >
                  <a href="https://policies.google.com/terms">
                    Google Terms of Service
                  </a>
                </Typography>
              </li>
            </ul>

            <Typography
              className="text-brand-heavy-teal-100 mb-[16px]"
              type="p"
              variant="base"
              weight="regular"
            >
              By using reCAPTCHA, you consent to the processing of data about
              you by Google in the manner and for the purposes set out above.
            </Typography>
          </div>
        </div>
      </Container>

      <ContactFormBanner />
      <EstateAgentBanner />
      <Footer />
    </>
  );
};
