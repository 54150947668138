import { ComponentProps } from "react";

export type RadioButtonsProps = ComponentProps<"input"> & {
  variant?: "enabled" | "disabled" | "success" | "error";
  options: string[];
  selectedOption: string | undefined;
  setSelectedOption: (value: string) => void;
};

const statusConfig = {
  enabled: " opacity-100",
  disabled: " opacity-50",
  success: " bg-ui-success-10 border-ui-success-100 border-2",
  error: " border-2",
};

export const RadioButtons = ({
  variant = "enabled",
  options,
  selectedOption,
  setSelectedOption,
}: RadioButtonsProps) => {
  const style =
    "flex min-w-[80px] px-[24px] py-[32px] leading-default cursor-pointer items-center justify-center text-brand-heavy-teal-100 text-base font-normal shadow-questionType border-2 rounded-lg focus:outline-none hover:border-brand-heavy-teal-100";
  const checkedStyle =
    "bg-brand-heavy-teal-100 text-ui-white-100 border-brand-heavy-teal-100";

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const statusClasses = statusConfig[variant] || "";

  return (
    <fieldset className="flex">
      {options.map((option) => (
        <div key={option} className="mr-[16px]">
          <label
            htmlFor={option}
            className={`${style} ${statusClasses} ${
              option === selectedOption
                ? checkedStyle
                : `bg-ui-white-100 ${variant === "enabled" ? "border-ui-white-100" : "border-ui-error-100"}`
            }`}
            onClick={() => handleOptionChange(option)}
          >
            {option}
          </label>

          <input
            id={option}
            name={option}
            value={option}
            type="radio"
            disabled={variant === "disabled"}
            defaultChecked={option === selectedOption}
            className="hidden"
          />
        </div>
      ))}
    </fieldset>
  );
};
