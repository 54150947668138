import { useEffect, useState } from "react";
import { useTransactionContext } from "../../context/TransactionProvider";
import { ThirdfortBlock } from "../TransactionBlocks/ThirdfortBlock";
import { PurchaseIntentUser } from "../TransactionDetailsTabContent/ThirdfortTab";
import { AdminUser } from "../../pages/UserDetails/types";
import { Giftors } from "../../pages/TransactionDetails/types";
import { ThirdfortPurchasersV2 } from "./ThirdfortPurchasersV2";

export const ThirdfortPurchasersV1 = () => {
  const { transaction } = useTransactionContext();

  const [purchaseIntentUsers, setPurchaseIntentUsers] = useState<
    PurchaseIntentUser[]
  >([]);

  useEffect(() => {
    if (!transaction) return;
    const transformPurchaseIntentUsers = transaction.purchase_intent.map(
      (i) => {
        const buyers: AdminUser[] = [];
        const giftors: Giftors[] = [];
        i.buyers.forEach((seller) => {
          buyers.push(seller.user);
          giftors.push(...(seller.giftors || []));
        });
        return { buyers, giftors };
      },
    );
    setPurchaseIntentUsers(transformPurchaseIntentUsers);
  }, [transaction]);

  return transaction
    ? purchaseIntentUsers.map(({ buyers, giftors }, purchaseIntentIndex) => {
        return (
          <>
            {buyers.map((buyer, index) => {
              return (
                <ThirdfortBlock
                  key={`thirdfort-buyer-${purchaseIntentIndex}-${index}`}
                  title={`Buyer ${index + 1}`}
                  address={transaction.property_id.address}
                  profile={buyer}
                  solicitorEmail={
                    transaction.lawyer_group?.buyer?.solicitor.email.toLowerCase() ||
                    ""
                  }
                  leftLabel={`Purchase Intent ${purchaseIntentIndex + 1} of ${purchaseIntentUsers.length} | Buyer ${index + 1} of ${buyers.length}`}
                  userType="Buyer"
                />
              );
            })}
            {giftors.map((giftor, index) => {
              return (
                <ThirdfortBlock
                  key={`thirdfort-giftor-${purchaseIntentIndex}-${index}`}
                  title={`Giftor ${index + 1}`}
                  address={transaction.property_id.address}
                  profile={giftor}
                  solicitorEmail={
                    transaction.lawyer_group?.buyer?.solicitor.email.toLowerCase() ||
                    ""
                  }
                  leftLabel={`Purchase Intent ${purchaseIntentIndex + 1} of ${purchaseIntentUsers.length} | Giftor ${index + 1} of ${giftors.length}`}
                  userType="Buyer"
                />
              );
            })}
          </>
        );
      })
    : null;
};

interface ThirdfortPurchasersProps {
  showV2Purchasers: boolean;
}

export const ThirdfortPurchasers = ({
  showV2Purchasers,
}: ThirdfortPurchasersProps) => (
  <>
    {showV2Purchasers && <ThirdfortPurchasersV2 />}
    <ThirdfortPurchasersV1 />
  </>
);
