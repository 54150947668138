import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { MonthlyTransactionCount } from "../../components/MonthlyTransactionsCount/MonthlyTransactionCount";

export const AdminDashboard = () => (
  <>
    <AdminTitle title="Admin Dashboard" />

    <div className="flex flex-col md:flex-row md:space-x-5">
      <div className="w-full mb-[24px]">
        <MonthlyTransactionCount />
      </div>
    </div>
  </>
);
