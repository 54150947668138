import { DropdownOption, LowercaseRoles, Roles } from "@shared/types";
import { LawyerGroup } from "../../../types";
import { LawyerGroups } from "../../pages/TransactionDetails/types";
import { CheckboxConfigGroup } from "./fields";

export const getLawyerGroupName = (lawyerGroup: LawyerGroup): string =>
  `${lawyerGroup.law_firm.name} - ${lawyerGroup.solicitor.first_name} ${lawyerGroup.solicitor.last_name}`;

export const getSelectedLawyerGroup = (
  lawyerGroups: LawyerGroup[],
  lawyerGroupIdSelected: string,
): LawyerGroup | null =>
  lawyerGroups.find((group) => group._id === lawyerGroupIdSelected) || null;

export const isSolicitorNonPanel = (
  lawyerGroups: LawyerGroup[],
  selectedId: string,
): boolean => {
  return lawyerGroups.some(
    (lawyerGroup) =>
      lawyerGroup._id === selectedId && !lawyerGroup.panel_solicitor,
  );
};

export const convertRoleToLowerCase = (role: Roles): LowercaseRoles =>
  role === Roles.Seller ? "seller" : "buyer";

export const getFieldValue = (
  lawyerGroup: LawyerGroups | undefined,
  role: LowercaseRoles,
  fieldValue: string,
): string => (lawyerGroup ? lawyerGroup[role]._id : fieldValue);

export const mapLawyerGroupsToOptions = (
  lawyers: LawyerGroup[],
  isNonPanel: boolean,
): {
  label: string;
  value: string;
}[] =>
  lawyers.map((lawyerGroup) => ({
    label: `${getLawyerGroupName(lawyerGroup)} ${
      isNonPanel ? "(Non-Panel)" : ""
    }`,
    value: lawyerGroup._id,
  }));

export const categoriseSolicitors = (
  lawyerGroups: LawyerGroup[],
): {
  panel: LawyerGroup[];
  nonPanel: LawyerGroup[];
} => {
  return lawyerGroups.reduce<{
    panel: LawyerGroup[];
    nonPanel: LawyerGroup[];
  }>(
    (acc, lawyerGroup) => {
      if (isSolicitorNonPanel(lawyerGroups, lawyerGroup._id)) {
        acc.nonPanel.push(lawyerGroup);
      } else {
        acc.panel.push(lawyerGroup);
      }
      return acc;
    },
    { panel: [], nonPanel: [] },
  );
};

export const checkboxConfigWithNoIntroductionToSellerEmail = (
  checkboxConfig: CheckboxConfigGroup,
): CheckboxConfigGroup => ({
  ...checkboxConfig,
  Seller: checkboxConfig.Seller.map((checkbox) =>
    checkbox.fieldName === "introductionEmailToSellerChecked"
      ? { ...checkbox, disabled: true }
      : checkbox,
  ),
});

export const checkboxConfigWithNoIntroductionToBuyerAndAdditionalInfoEmails = (
  checkboxConfig: CheckboxConfigGroup,
): CheckboxConfigGroup => ({
  ...checkboxConfig,
  Buyer: checkboxConfig.Buyer.map((checkbox) =>
    checkbox.fieldName === "introductionEmailToBuyerChecked" ||
    checkbox.fieldName === "additionalInfoEmailToBuyerSolicitorChecked"
      ? { ...checkbox, disabled: true }
      : checkbox,
  ),
});

export const generateLawyerGroupOptions = (
  lawyerGroups: LawyerGroup[],
): DropdownOption<string>[] => [
  { label: "Select a solicitor", value: "", disabled: true },
  ...lawyerGroups.map((lawyerGroup) => ({
    label: `${getLawyerGroupName(lawyerGroup)} ${
      isSolicitorNonPanel(lawyerGroups, lawyerGroup._id) ? "(Non-Panel)" : ""
    }`,
    value: lawyerGroup._id,
  })),
];
