import { useEffect, useState } from "react";

import { Card } from "@pongo";

import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { EstateAgent } from "../../../types";

import { AddValuer } from "./AddValuer";
import { EditValuer } from "./EditValuer";

type Valuer = EstateAgent["valuers"][0];

export const EstateAgentValuersBlock = () => {
  const { estateAgent } = useEstateAgentContext();

  const [valuers, setValuers] = useState<Valuer[]>([]);

  useEffect(() => {
    const returnPrimaryFirst = (a: Valuer, b: Valuer) =>
      Number(b.primary_valuer) - Number(a.primary_valuer);

    setValuers(estateAgent?.valuers.sort(returnPrimaryFirst) || []);
  }, [estateAgent]);

  return (
    <>
      <Card title="Valuers">
        <div className="flex flex-wrap gap-5">
          {valuers.map((valuer, index) => (
            <EditValuer
              valuer={valuer}
              valuers={valuers}
              index={index}
              key={`valuer-${index}`}
            />
          ))}
          {(estateAgent?.valuers.length || 0) < 2 ? <AddValuer /> : null}
        </div>
      </Card>
    </>
  );
};
