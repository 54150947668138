import { z } from "zod";

import { tenureOption } from "@shared/types";

import { AddressSchema } from "./AddressSchema";
import { PersonalDetailsWithAddressSchema } from "./PersonalDetailsSchema";
import { requiredField } from "./helpers";

export const manualTransactionPropertySchema = z.object({
  propertyAddress: AddressSchema,
  askingPrice: z.string().refine(
    (price) => {
      const numericPrice = parseFloat(price.replace(/,/g, "")); // Remove commas and convert to float
      return !isNaN(numericPrice) && numericPrice >= 0;
    },
    {
      message: "Asking price must be higher than £0",
    },
  ),
  tenureOption: requiredField(z.enum(tenureOption)),
});

export const companySellerSchema = z.object({
  companyId: z.string().optional(),
  name: z
    .string()
    .min(2, { message: "Name must be at least 2 characters long" })
    .max(255, { message: "Name must be less than 255 characters long" }),
  address: AddressSchema,
  companyNumber: z
    .string()
    .min(2, { message: "Company number must be at least 2 characters long" })
    .max(255, {
      message: "Company number must be less than 255 characters long",
    }),
});

export const manualTransactionSchema = z.object({
  _id: z.string().optional(),
  property: manualTransactionPropertySchema,
});

export const groupTransactionsSchema = z.object({
  manualTransactions: z.array(manualTransactionSchema),
  representative: PersonalDetailsWithAddressSchema,
  companySeller: companySellerSchema,
});

export const manualTransactionsSchema = z.object({
  manualTransactions: z.array(manualTransactionSchema),
});

export const transactionsGroupSchema = z.object({
  name: z
    .string()
    .min(2, { message: "Name must be at least 2 characters long" }),
  _id: z.string().optional(),
});
