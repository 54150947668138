import { useEffect, useMemo, useRef, useState } from "react";
import { FormikErrors } from "formik";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import { Footer } from "../../../components/Footer/Footer";
import { RepresentativeDetailsBlock } from "./RepresentativeDetailsBlock";
import { SellerDetailsBlock } from "./SellerDetailsBlock";
import { PropertyDetailsBlock } from "./PropertyDetailsBlock";
import { PaymentDetailsBlock } from "./PaymentDetailsBlock";
import { TransactionsGroupNameBlock } from "./TransactionsGroupNameBlock";
import {
  ApiResponse,
  useTransactionsGroupContext,
} from "../../context/TransactionsGroupProvider";
import ErrorText from "@/foundation/Typography/ErrorText";
import { Button } from "@/components/Button/Button";
import { createManualTransactionsRequestBody } from "./utils";
import { ApiController } from "../../../service/ApiController";
import { products } from "../../../pages/Payment/products";

export const TransactionsGroupBlock = <T extends object>() => {
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const {
    manualTransactions,
    representative,
    companySeller,
    transactionsGroup,
    stripeTransactionId,
    createRepresentative,
    createCompanySeller,
    setTransactionsGroup,
  } = useTransactionsGroupContext();

  const handlePropertySubmit = useRef<(() => void) | null>(null);
  const handleGroupNameSubmit = useRef<(() => void) | null>(null);
  const handleRepresentativeSubmit = useRef<(() => void) | null>(null);
  const handleSellerSubmit = useRef<(() => void) | null>(null);

  const handleFormErrors = (errors: FormikErrors<T> | undefined) => {
    setIsValid(!errors);
  };

  const isFormValid = useMemo(() => {
    return Boolean(
      transactionsGroup?.name &&
        representative &&
        companySeller &&
        manualTransactions[0]?.property.askingPrice !== "",
    );
  }, [transactionsGroup, representative, companySeller, manualTransactions]);

  useEffect(() => {
    setIsValid(isFormValid);
  }, [isFormValid]);

  const createAPIEntity = async (
    entity: () => Promise<ApiResponse>,
  ): Promise<string> => {
    const result = await entity();

    if (result.error || !result.id) {
      setIsValid(false);
      throw new Error(
        result.error || "There was an error while creating transactions group",
      );
    }

    return result.id;
  };

  const handleCreateManualTransactions = async () => {
    try {
      [
        handlePropertySubmit,
        handleRepresentativeSubmit,
        handleSellerSubmit,
        handleGroupNameSubmit,
      ].forEach((submitRef) => submitRef.current?.());

      if (!isFormValid) {
        throw new Error("Validation failed. Please check all required fields.");
      }

      // Create representative and company seller entities in the DB:
      const representativeId = await createAPIEntity(createRepresentative);
      const companySellerId = await createAPIEntity(createCompanySeller);

      const requestBody = createManualTransactionsRequestBody(
        manualTransactions,
        companySeller,
        representative,
        representativeId,
        transactionsGroup,
        stripeTransactionId,
        products,
        companySellerId,
      );

      // Create transactions in the DB:
      const manualTransactionsResponse =
        await ApiController.createManualTransactions(requestBody);

      // Set the transactions group in the context:
      setTransactionsGroup({
        ...manualTransactionsResponse,
        hasActivePurchaseIntent: false,
      });

      setError(null);
      setTimeout(() => {
        navigate("/admin/transactions-groups/success");
      }, 200);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else if (error instanceof AxiosError) {
        setError(error.response?.data.message);
      } else {
        setError("An unknown error occurred");
      }
    }
  };

  return (
    <>
      {error ? (
        <div className="flex flex-col gap-[16px]">
          <ErrorText>{error}. Please go back and try again.</ErrorText>
          <Button
            type="button"
            size="xl"
            variant="primary"
            onClick={() => navigate(0)}
            className="self-center"
          >
            Go back
          </Button>
        </div>
      ) : (
        <>
          <div className="flex flex-col space-y-5 pb-[24px]">
            <TransactionsGroupNameBlock
              submitTrigger={(submit) =>
                (handleGroupNameSubmit.current = submit)
              }
              onError={handleFormErrors}
            />
            <RepresentativeDetailsBlock
              submitTrigger={(submit) =>
                (handleRepresentativeSubmit.current = submit)
              }
              onError={handleFormErrors}
            />
            <PropertyDetailsBlock
              submitTrigger={(submit) =>
                (handlePropertySubmit.current = submit)
              }
              onError={handleFormErrors}
            />
            <SellerDetailsBlock
              submitTrigger={(submit) => (handleSellerSubmit.current = submit)}
              onError={handleFormErrors}
            />
            <PaymentDetailsBlock />
          </div>

          <Footer
            continueTitle="Save and Continue"
            isValid={isValid}
            handleContinue={() => handleCreateManualTransactions()}
          />
        </>
      )}
    </>
  );
};
