import validator from "validator";
import { z } from "zod";

export const refineMobileNumber = (phoneNumber: string) => {
  const numericPhoneNumber = parseFloat(phoneNumber.replace(/,/g, ""));
  return (
    !isNaN(numericPhoneNumber) &&
    numericPhoneNumber.toString().length >= 5 &&
    validator.isMobilePhone(phoneNumber)
  );
};

export const requiredField = <
  T extends z.ZodType<unknown, z.ZodTypeDef, unknown>,
>(
  schema: T,
  message = "Please select an option",
) => schema.refine((value) => value !== undefined, { message });
