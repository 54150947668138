import { useEffect, useState } from "react";

import { Card, ErrorText, Typography } from "@pongo";
import { ApiController } from "../../../service/ApiController";

export const MonthlyTransactionCount = () => {
  const [currentMonthAndYear, setCurrentMonthAndYear] = useState<string>(
    new Date().toLocaleString("default", { month: "long", year: "numeric" }),
  );
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const getTransactionsByMonth = async () => {
    const currentDate = new Date();

    currentDate.setMonth(currentDate.getMonth());

    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    try {
      const total = await ApiController.getMonthlyTransactionCount({
        month: (currentMonth + 1).toString(),
        year: currentYear.toString(),
      });
      setTotalTransactions(total);
    } catch {
      setError("Failed to fetch total transactions");
      setTotalTransactions(0);
    }

    setCurrentMonthAndYear(
      currentDate.toLocaleString("default", { month: "long", year: "numeric" }),
    );
  };

  useEffect(() => {
    getTransactionsByMonth();
  }, []);

  return (
    <>
      {error ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <div className="w-full lg:w-1/3">
          <Card
            title="Total Transactions"
            titleBackgroundColor="brand-heavy-warmth-25"
            titleColor="brand-heavy-teal-100"
          >
            <div className="flex">
              <Typography weight="bold">{currentMonthAndYear}:</Typography>
              <Typography className="px-[6px]">{totalTransactions}</Typography>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};
