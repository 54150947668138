import { useNavigate } from "react-router-dom";

import { BuyerPropertyDetailsState } from "@schema";

import { sellAboutTheProperty } from "../../components/Header/allStepperOptions";
import { PageTemplate } from "../../components/Form/PageTemplate/PageTemplate";
import { BuyerAboutThePropertyForm } from "../../components/Form/DetailsForm/AboutThePropertyForm/BuyerAboutThePropertyForm";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";

export const BuyerAboutTheProperty = () => {
  const { purchaseIntent, updatePurchaseIntent } = usePurchaseIntentFormStore();
  const navigate = useNavigate();

  const handleSubmit = (values: BuyerPropertyDetailsState): void => {
    updatePurchaseIntent({
      ...purchaseIntent,
      property: values,
    });

    navigate("/buy/terms-and-conditions");
  };

  return (
    <PageTemplate
      title="About the property"
      stepperOptions={sellAboutTheProperty}
    >
      <BuyerAboutThePropertyForm
        state={purchaseIntent.property}
        onSubmit={handleSubmit}
      />
    </PageTemplate>
  );
};
