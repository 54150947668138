import { ComponentProps, useRef } from "react";

import { DropdownOption } from "@shared/types";
import { statusConfig } from "./statusConfig";

export type SelectProps<T> = ComponentProps<"select"> & {
  variant?: "enabled" | "disabled" | "success" | "error";
  width?: "base" | "sm" | "xs";
  options: DropdownOption<T>[];
};

export const Select = <T,>({
  variant = "enabled",
  width = "base",
  options,
  name,
  ...props
}: SelectProps<T>) => {
  const inputRef = useRef<HTMLSelectElement>(null);

  const inputClasses =
    "appearance-none cursor-pointer bg-no-repeat bg-right bg-contain h-[64px] pl-7 pt-[22px] pl-[24px] pr-[48px] shadow-questionType rounded-lg border-2 hover:border-ui-black-25 flex flex-col text-base text-ui-black-75 leading-none focus:outline-none focus:shadow-outline";

  const statusClasses = statusConfig[variant] || "";

  const stringifiedValue = (value: T) => {
    if (typeof value === "string") {
      return value;
    }

    return JSON.stringify(value);
  };

  let sizeStyle;

  switch (width) {
    case "base":
      sizeStyle = "w-full md:w-[320px]";
      break;
    case "sm":
      sizeStyle = "w-[240px]";
      break;
    case "xs":
      sizeStyle = "w-[160px]";
      break;
  }

  return (
    <div className="w-full relative">
      <select
        {...props}
        ref={inputRef}
        className={`${inputClasses} ${statusClasses} ${sizeStyle}`}
        style={{
          backgroundImage: `url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0%207.63099L2.52261%205L11.9734%2014.504L21.4223%205L24%207.57769L11.9734%2019.6593L0%207.63099Z%22%20fill%3D%22%23131313%22%2F%3E%3C%2Fsvg%3E")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 1.3rem top 50%",
          backgroundSize: "1rem auto",
        }}
        disabled={variant === "disabled"}
      >
        {options.map((option, index) => {
          const value = stringifiedValue(option.value);
          return option.isLineBreak ? (
            <option key={`linebreak-${index}`} disabled>
              {option.label}
            </option>
          ) : (
            <option
              key={`${name}-${value}-${index}`}
              value={value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
