import { Typography } from "@pongo";

import { toTitleCase } from "@shared/utils";

interface AdminTitleProps {
  children?: React.ReactNode;
  title?: string;
}

export const AdminTitle = ({
  title = "Unknown",
  children,
}: AdminTitleProps) => (
  <>
    <div className="w-full md:flex justify-between items-start pt-[20px]">
      <Typography
        type="h1"
        variant="h3"
        weight="bold"
        className="mb-[32px] text-brand-heavy-teal-100 font-display"
        data-testid="AdminTitle-title"
      >
        {toTitleCase(title)}
      </Typography>

      {children}
    </div>
  </>
);
