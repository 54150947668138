import { z } from "zod";

import { customerType } from "@shared/types";

import { refineMobileNumber } from "./helpers";
import { AddressSchema } from "./AddressSchema";

export const PersonalDetailsSchema = z.object({
  firstName: z
    .string()
    .min(2, { message: "First name must be at least 2 characters long" })
    .max(255, { message: "First name must be less than 255 characters long" }),
  middleName: z.string().optional(),
  lastName: z
    .string()
    .min(2, { message: "Last name must be at least 2 characters long" })
    .max(255, { message: "Last name must be less than 255 characters long" }),
  email: z.string().email(),
  mobileNumber: z
    .string()
    .refine((phoneNumber) => refineMobileNumber(phoneNumber), {
      message: "Invalid mobile number",
    }),
  termsAndConditions: z.boolean(),
  primaryCustomer: z.boolean(),
});

export const PersonalDetailsWithAddressSchema = z.object({
  personId: z.string(),
  firstName: PersonalDetailsSchema.shape.firstName,
  middleName: PersonalDetailsSchema.shape.middleName.optional(),
  lastName: PersonalDetailsSchema.shape.lastName,
  email: PersonalDetailsSchema.shape.email,
  mobileNumber: PersonalDetailsSchema.shape.mobileNumber,
  termsAndConditions: PersonalDetailsSchema.shape.termsAndConditions,
  primaryCustomer: PersonalDetailsSchema.shape.primaryCustomer,
  customerType: z.enum(customerType).optional(),
  correspondenceAddress: AddressSchema,
});
