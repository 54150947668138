import { useEffect, useState } from "react";
import { Link as ScrollToLink } from "react-scroll";

import { Container } from "../../../components/Container/Container";
import { useTabletMediaQuery } from "../../../mediaQueries";

import LogoWhite from "../../../assets/logo-horizontal-white.svg";
import LogoTeal from "../../../assets/logo-horizontal-teal.svg";

interface HeaderProps {
  theme: "light" | "dark";
}

export const Header = ({ theme }: HeaderProps) => {
  const isTablet = useTabletMediaQuery();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let linkStyles = "border-opacity-0 hover:border-opacity-100";
  let navigationStyles;

  if (theme === "light") {
    linkStyles += " text-ui-white-100 border-b-2 border-b-ui-white-100";
    if (scrolled) {
      navigationStyles = "bg-ui-black-100/60";
    }
  }

  if (theme === "dark") {
    linkStyles +=
      " text-brand-heavy-teal-100 border-b-2 border-b-brand-heavy-teal-100";
    if (scrolled) {
      navigationStyles = "bg-brand-heavy-warmth-100/80";
    }
  }

  const buttonStyles =
    "text-base text-center rounded-lg px-[16px] md:px-[32px] md:w-[150px] h-12";

  const darkButtonStyles = `border border-brand-heavy-teal-100 text-brand-heavy-teal-100 bg-trasparent hover:bg-brand-heavy-teal-100 hover:text-ui-white-100 flex leading-default items-center justify-center focus:outline-none ${buttonStyles}`;

  const lightButtonStyles = `text-base text-ui-white-100 bg-transparent border border-ui-white-100 hover:bg-ui-white-100 hover:text-brand-heavy-teal-75 flex leading-default items-center justify-center focus:outline-none ${buttonStyles}`;

  return (
    <nav
      className={`fixed left-0 right-0 top-0 z-20 ${navigationStyles} transition-all duration-300 ease-in-out`}
    >
      <Container maxWidth={1440}>
        <div className="flex items-center justify-between w-full py-[16px] px-[16px] md:py-[24px] md:px-[40px]">
          <div
            className="w-full flex justify-between items-center"
            data-testid="nav"
          >
            <a href="/">
              <img
                src={theme === "light" ? LogoWhite : LogoTeal}
                alt=""
                width={isTablet ? 166 : 130}
              />
            </a>
          </div>

          <div className="flex items-center shrink-0">
            <div className="mr-[16px] md:mr-[28px]">
              <a href="/partner-sign-up" className={`shrink-0 ${linkStyles}`}>
                Estate Agents
              </a>
            </div>

            <div className="mr-[16px] md:mr-[28px]">
              <ScrollToLink
                to="contact"
                smooth
                duration={1000}
                className={
                  theme === "light" ? lightButtonStyles : darkButtonStyles
                }
              >
                Get Started
              </ScrollToLink>
            </div>

            <div className="mr-[16px] md:mr-[28px]">
              <a href="/sign-in" className={`shrink-0 ${linkStyles}`}>
                Sign In
              </a>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
};
