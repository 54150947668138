import { z } from "zod";

import {
  BooleanPlusNotSure,
  CustomerType,
  OwnershipType,
  RelationshipType,
  TenureOption,
} from "@shared/types";

import { refineMobileNumber } from "./helpers";

import { SignUpSchema, SignUpType } from "./SignUpSchema";
import { SignInSchema, SignInType } from "./SignInSchema";
import {
  companySellerSchema,
  groupTransactionsSchema,
  manualTransactionPropertySchema,
  manualTransactionSchema,
  manualTransactionsSchema,
  transactionsGroupSchema,
} from "./TransactionsGroupSchema";
import { SellerPropertyDetailsSchema } from "./SellerPropertyDetailsSchema";
import { BuyerPropertyDetailsSchema } from "./BuyerPropertyDetailsSchema";
import {
  PersonalDetailsWithAddressSchema,
  PersonalDetailsSchema,
} from "./PersonalDetailsSchema";
import { AddressSchema } from "./AddressSchema";
import { MortgageProviderSchema } from "./MortgageProviderSchema";
import { GiftorSchema } from "./GiftorSchema";
import { FundingSchema } from "./FundingSchema";
import { FEATURE_FLAGS } from "../constants";

export const ContactFormSchema = z.object({
  fullName: z.string().min(2, { message: "Full name is required" }),
  mobileNumber: z
    .string()
    .refine((phoneNumber) => refineMobileNumber(phoneNumber), {
      message: "Invalid mobile number",
    })
    .nullish(),
  email: z
    .string()
    .email("Invalid email address")
    .min(2, { message: "Email is required" }),
  message: z.string().min(2, { message: "Message is required" }),
  referrer: z.string().min(2, { message: "Referrer is required" }),
});

type AddressState = z.infer<typeof AddressSchema>;

// About you details:
type PersonalDetailsState = z.infer<typeof PersonalDetailsSchema>;
type PersonalDetailsWithAddressState = z.infer<
  typeof PersonalDetailsWithAddressSchema
>;

// Seller property details:
type BaseSellerPropertyDetailsState = Omit<
  z.infer<typeof SellerPropertyDetailsSchema>,
  | "tenureOption"
  | "hasMortgage"
  | "onwardPurchase"
  | "customerType"
  | "isUsingForeignCurrency"
  | "isAnotherSellerSelected"
> & {
  tenureOption: TenureOption | undefined;
  hasMortgage: boolean | undefined;
  onwardPurchase: boolean | undefined;
  customerType: CustomerType | undefined;
  isUsingForeignCurrency: boolean | undefined;
  isAnotherSellerSelected: boolean | undefined;
};

type SellerPropertyDetailsState =
  typeof FEATURE_FLAGS.isUsingForeignCurrency extends true
    ? BaseSellerPropertyDetailsState & {
        isUsingForeignCurrency: boolean | undefined;
      }
    : BaseSellerPropertyDetailsState;

// Buyer property details:
type BaseBuyerPropertyDetailsState = Omit<
  z.infer<typeof BuyerPropertyDetailsSchema>,
  | "mortgageRequiredForPurchase"
  | "purchaseDependentOnSale"
  | "ownershipType"
  | "firstTimeBuyer"
  | "customerType"
  | "giftors"
  | "funding"
  | "isUsingForeignCurrency"
> & {
  mortgageRequiredForPurchase: BooleanPlusNotSure | undefined;
  purchaseDependentOnSale: boolean | undefined;
  ownershipType: OwnershipType | undefined;
  firstTimeBuyer: boolean | undefined;
  customerType: CustomerType | undefined;
  giftors: GiftorSchemaState[];
  funding: FundingSchemaState;
  isUsingForeignCurrency: boolean | undefined;
};

type BuyerPropertyDetailsState =
  typeof FEATURE_FLAGS.isUsingForeignCurrency extends true
    ? BaseBuyerPropertyDetailsState & {
        isUsingForeignCurrency: boolean | undefined;
      }
    : BaseBuyerPropertyDetailsState;
type GiftorSchemaState = Omit<z.infer<typeof GiftorSchema>, "relationship"> & {
  relationship: RelationshipType | undefined;
};
type FundingSchemaState = Omit<z.infer<typeof FundingSchema>, "gifts"> & {
  gifts: BooleanPlusNotSure | undefined;
};
type MortgageProviderState = z.infer<typeof MortgageProviderSchema>;

// Transactions group:
type CompanySellerState = z.infer<typeof companySellerSchema>;
type ManualTransactionState = Omit<
  z.infer<typeof manualTransactionSchema>,
  "property"
> & {
  property: ManualTransactionPropertyState;
};
type ManualTransactionsState = Omit<
  z.infer<typeof manualTransactionsSchema>,
  "manualTransactions"
> & { manualTransactions: ManualTransactionState[] };
type ManualTransactionPropertyState = Omit<
  z.infer<typeof manualTransactionPropertySchema>,
  "tenureOption"
> & { tenureOption: TenureOption | undefined };
type GroupTransactionsState = Omit<
  z.infer<typeof groupTransactionsSchema>,
  "manualTransactions"
> & { manualTransactions: ManualTransactionState[] };
type TransactionsGroupState = z.infer<typeof transactionsGroupSchema>;

export {
  SignUpSchema,
  SignInSchema,
  BuyerPropertyDetailsSchema,
  SellerPropertyDetailsSchema,
  PersonalDetailsWithAddressSchema,
  GiftorSchema,
  manualTransactionsSchema,
  companySellerSchema,
};

export type {
  SignUpType,
  SignInType,
  AddressState,
  PersonalDetailsState,
  PersonalDetailsWithAddressState,
  SellerPropertyDetailsState,
  BuyerPropertyDetailsState,
  GiftorSchemaState,
  MortgageProviderState,
  CompanySellerState,
  ManualTransactionState,
  ManualTransactionsState,
  ManualTransactionPropertyState,
  GroupTransactionsState,
  TransactionsGroupState,
};
