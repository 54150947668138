import { Container } from "../../../components/Container/Container";
import { useTabletMediaQuery } from "../../../mediaQueries";

interface BannerProps {
  backgroundColour: string;
  imageUrl: string;
  children?: React.ReactNode;
}

export const Banner = ({
  backgroundColour,
  imageUrl,
  children,
}: BannerProps) => {
  const isTablet = useTabletMediaQuery();

  return (
    <div className={backgroundColour}>
      <Container maxWidth={1150}>
        <div
          className={`px-[24px] py-[42px] flex ${isTablet ? "flex-row" : "flex-col"} items-center`}
        >
          {imageUrl && (
            <img
              src={imageUrl}
              className={`w-[100%] max-w-[471px] ${isTablet ? "-ml-[180px]" : "ml-0"}`}
            />
          )}

          <div className="w-full">{children}</div>
        </div>
      </Container>
    </div>
  );
};
