import {
  BuyerPropertyDetailsState,
  GiftorSchemaState,
  PersonalDetailsWithAddressState,
  SellerPropertyDetailsState,
} from "@schema";

export const initialSellerPropertyDetailsStateObj: SellerPropertyDetailsState =
  {
    propertyAddress: {
      line_1: "",
      line_2: "",
      line_3: "",
      post_town: "",
      country: "",
      postcode: "",
      county: "",
      uprn: "",
      district: "",
      building_name: "",
      building_number: "",
      sub_building_name: "",
      premise: "",
      organisation_name: "",
      po_box: "",
    },
    askingPrice: "",
    tenureOption: undefined,
    tenureDetails: "",
    hasMortgage: undefined,
    onwardPurchase: undefined,
    customerType: undefined,
    additionalSellers: [],
    isAnotherSellerSelected: undefined,
    isUsingForeignCurrency: undefined,
  };

export const initialBuyerPropertyDetailsStateObj: BuyerPropertyDetailsState = {
  _id: "",
  property_id: "",
  agreedPrice: "",
  mortgageRequiredForPurchase: undefined,
  mortgageProvider: undefined,
  purchaseDependentOnSale: undefined,
  funding: {
    gifts: undefined,
    savingsFromEmployment: false,
    investmentPortfolio: false,
    remortgage: false,
    inheritance: false,
    divorceSettlement: false,
    pensionLumpSumWithdrawal: false,
    saleOfShares: false,
    directorsLoansDividends: false,
    otherFunding: false,
    otherFundingOption: "",
    otherFundingComments: "",
  },
  ownershipType: undefined,
  firstTimeBuyer: undefined,
  customerType: undefined,
  additionalBuyers: [],
  giftors: [],
  isUsingForeignCurrency: undefined,
};

export const initialPersonalDetailsStateObj: PersonalDetailsWithAddressState = {
  personId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  termsAndConditions: false,
  primaryCustomer: false,
  correspondenceAddress: {
    line_1: "",
    line_2: "",
    line_3: "",
    post_town: "",
    country: "",
    postcode: "",
  },
  customerType: undefined,
};

export const initialGiftorStateObj: GiftorSchemaState = {
  personId: "",
  amount: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  relationship: undefined,
  correspondenceAddress: {
    line_1: "",
    line_2: "",
    line_3: "",
    post_town: "",
    country: "",
    postcode: "",
  },
};
