import { useState } from "react";

import { AddressState } from "@schema";
import { Typography, Dropdown } from "@pongo";

import { Property } from "./types";
import { ApiController } from "../../../../service/ApiController";
import { EMAIL_ADDRESS } from "../../../../constants";
import { typosErrorMessage } from "./errorMessages";
import { getAddressesAndPropertiesOptions } from "./utils";
import { PostcodeInput } from "./PostcodeInput";

interface PostcodeLookupProps {
  handleSetAddress: (addressValues: AddressState) => void;
}

export const PostcodeLookup = ({ handleSetAddress }: PostcodeLookupProps) => {
  const [listOfAddresses, setListOfAddresses] = useState<Property[]>([]);
  const [APIErrorMessage, setAPIErrorMessage] = useState<string>("");

  const handlePostcodeLookup = async (inputPostcode: string) => {
    try {
      // Look up the address using the postcode API:
      const addresses = await ApiController.findAddress(inputPostcode);

      if (!addresses) {
        setAPIErrorMessage(typosErrorMessage);
        return;
      } else {
        setAPIErrorMessage("");

        setListOfAddresses(
          addresses.map((property: Property) => ({
            address: {
              ...property,
            },
          })),
        );

        return addresses;
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  return (
    <>
      <PostcodeInput handlePostcodeLookup={handlePostcodeLookup} />

      {/* API addresses */}

      {listOfAddresses.length > 0 && (
        <Dropdown
          variant="enabled"
          name="properties"
          className="my-[16px]"
          options={getAddressesAndPropertiesOptions(listOfAddresses).map(
            (x) => ({ label: x.label, id: x.value }),
          )}
          placeholder="Please select your address"
          onChange={(selectedValue) => {
            const selectedIndex = listOfAddresses.findIndex(
              (property) =>
                `${property.address.line_1}, ${property.address.line_2}` ===
                selectedValue,
            );
            if (selectedIndex !== -1) {
              const selectedProperty = listOfAddresses[selectedIndex];
              handleSetAddress(selectedProperty.address);
            }
          }}
        />
      )}

      {APIErrorMessage && (
        <div className="mb-[12px] text-ui-error-100">
          <Typography type="span" variant="xs" weight="regular">
            {APIErrorMessage}
          </Typography>
          <a
            className="text-xs leading-default"
            href={`mailto:${EMAIL_ADDRESS.SUPPORT}`}
          >
            {EMAIL_ADDRESS.SUPPORT}.
          </a>
        </div>
      )}
    </>
  );
};
