import { Typography, ErrorText, RadioButtons } from "@pongo";
export interface RadioButtonQuestionProps {
  title: string;
  description?: string;
  label: string;
  options: string[];
  selectedRadioButtonQuestionOption: string | undefined;
  setSelectedRadioButtonQuestionOption: (option: string | null) => void;
  radioButtonQuestionError?: string;
}

export const RadioButtonQuestion = ({
  title,
  description,
  label,
  options,
  selectedRadioButtonQuestionOption,
  setSelectedRadioButtonQuestionOption,
  radioButtonQuestionError,
}: RadioButtonQuestionProps) => (
  <>
    <label htmlFor={label} className="mb-[8px] mt-[32px]">
      <Typography
        type="h2"
        variant="xl"
        weight="bold"
        className="text-brand-heavy-teal-100"
      >
        {title}
      </Typography>
    </label>

    {description && (
      <Typography
        type="p"
        variant="base"
        weight="regular"
        className="text-brand-heavy-teal-100 mt-[4px] mb-[16px] max-w-[750px]"
      >
        {description}
      </Typography>
    )}

    <div className="flex">
      <RadioButtons
        options={options}
        selectedOption={selectedRadioButtonQuestionOption}
        setSelectedOption={setSelectedRadioButtonQuestionOption}
        variant={
          radioButtonQuestionError && radioButtonQuestionError !== ""
            ? "error"
            : "enabled"
        }
      />
    </div>

    <div className="h-[16px]">
      {radioButtonQuestionError && radioButtonQuestionError !== "" && (
        <ErrorText>{radioButtonQuestionError}</ErrorText>
      )}
    </div>
  </>
);
