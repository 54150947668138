import React from "react";

export interface TypographyProps {
  variant?:
    | "marketingTitle"
    | "title"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "xl"
    | "lg"
    | "base"
    | "sm"
    | "xs"
    | "allCaps";
  weight?: "regular" | "bold" | "semi-bold";
  children: React.ReactNode;
  className?: string;
  type?: "h1" | "h2" | "h3" | "h4" | "p" | "span";
}

export const typographyConfig = {
  marketingTitle: "text-marketing-title leading-default",
  title: "text-5xl leading-default",
  h1: "text-4xl leading-default",
  h2: "text-3xl leading-default",
  h3: "text-2xl leading-default",
  h4: "text-xl leading-default",
  xl: "text-xl leading-default",
  lg: "text-lg leading-default",
  base: "text-base leading-default",
  sm: "text-sm leading-default",
  xs: "text-xs leading-default",
  allCaps: "text-xs leading-100 uppercase",
};

export const Typography = ({
  variant = "base",
  weight = "regular",
  children = "",
  type = "p",
  className,
  ...props
}: TypographyProps) => {
  let text = "";

  if (variant in typographyConfig) {
    text += typographyConfig[variant];
  }

  if (weight === "bold") {
    text += " font-bold";
  } else if (weight === "semi-bold") {
    text += " font-medium";
  } else {
    text += " font-normal";
  }

  return React.createElement(
    type,
    { className: `${text} ${className}`, ...props },
    children,
  );
};

export default Typography;
