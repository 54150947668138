import { PersonalDetailsWithAddressState } from "@schema";
import { UpdateUserRequest } from "../../service/ApiController/updateUser";

export const transformUpdatePrimarySellerForUpdateCustomerApi = (
  primarySeller: PersonalDetailsWithAddressState,
): UpdateUserRequest => {
  const returnedPrimarySeller: UpdateUserRequest = {
    email: primarySeller.email,
    first_name: primarySeller.firstName,
    last_name: primarySeller.lastName,
    middle_name: primarySeller.middleName,
    profile: {
      phone_number: primarySeller.mobileNumber,
      correspondence_address: primarySeller.correspondenceAddress,
    },
  };

  return returnedPrimarySeller;
};
