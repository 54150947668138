import { Typography, ErrorText, SelectableCardGroup } from "@pongo";

import { relationshipToSaleCard } from "../Fields";

export interface CustomerTypeProps {
  relationshipToSaleOptions: string[];
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  customerTypeError?: string;
}

export const CustomerType = ({
  relationshipToSaleOptions,
  selectedOption,
  setSelectedOption,
  customerTypeError,
}: CustomerTypeProps) => (
  <>
    <label htmlFor="customerType" className="mb-[16px] mt-[32px]">
      <Typography
        type="h2"
        variant="xl"
        weight="bold"
        className="text-brand-heavy-teal-100"
      >
        Relationship to the sale
      </Typography>
    </label>

    <Typography className="text-brand-heavy-teal-100 max-w-[750px] mb-[16px]">
      Please select the option that best describes your relationship to the
      sale.
    </Typography>

    <div className="w-full">
      <SelectableCardGroup
        options={relationshipToSaleOptions}
        radioName="customerType"
        card={relationshipToSaleCard.map((card) => {
          return {
            ...card,
            icon: selectedOption === card.option ? card.whiteIcon : card.icon,
          };
        })}
        handleChange={(option: string) => {
          setSelectedOption(option);
        }}
        value={selectedOption}
        variant={customerTypeError ? "error" : "enabled"}
      />

      <div className="h-[16px]">
        {customerTypeError && <ErrorText>{customerTypeError}</ErrorText>}
      </div>
    </div>
  </>
);
