import { Card } from "@pongo";

import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { EditNegotiator } from "./EditNegotiator";
import { AddNegotiator } from "./AddNegotiator";

export const EstateAgentNegotiatorsBlock = () => {
  const { estateAgent } = useEstateAgentContext();

  return (
    <>
      <Card title="Negotiators">
        <div className="flex flex-wrap gap-5">
          {estateAgent?.negotiators?.map((negotiator, index) => (
            <EditNegotiator
              negotiator={negotiator}
              negotiators={estateAgent?.negotiators || []}
              index={index}
              key={`negotiator-${index}`}
            />
          ))}
          {(estateAgent?.negotiators?.length || 0) < 5 ? (
            <AddNegotiator />
          ) : null}
        </div>
      </Card>
    </>
  );
};
