export const navigationItems = [
  {
    label: "Transactions",
    path: "/admin/transactions",
    icon: "AdminTransactions",
  },
  {
    label: "Transactions Groups",
    path: "/admin/transactions-groups",
    icon: "AdminTransactionGroups",
  },
  {
    label: "Law Firms",
    path: "/admin/law-firms",
    icon: "AdminLawFirms",
  },
  {
    label: "Estate Agents",
    path: "/admin/estate-agents",
    icon: "AdminEstateAgents",
  },
  { label: "Users", path: "/admin/users", icon: "AdminUsers" },
];
