import { Card, Typography } from "@pongo";

import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { getValue } from "../Table/Table";

interface CheckPropertyDetailsProps {
  transaction: AdminTransaction;
}

const handleMapsUrl = (transaction: AdminTransaction) => {
  const fullAddress = `${transaction.property_id.address.line_1} ${transaction.property_id.address.line_2} ${transaction.property_id.address.post_town} ${transaction.property_id.address.postcode}`;
  const encodedAddress = encodeURIComponent(fullAddress);

  return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
};

export const CheckPropertyDetails = ({
  transaction,
}: CheckPropertyDetailsProps) => {
  const checkPropertyDetailsSection = [
    {
      id: "1",
      label: "Property address",
      value: `${transaction?.property_id.address.line_1}, ${transaction?.property_id.address.line_2} ${transaction?.property_id.address.post_town}, ${transaction?.property_id.address.postcode}`,
    },
    {
      id: "2",
      label: "Seller names",
      value: `${transaction?.sellers.map((seller) => {
        return `${transaction && getValue(seller.user, "first_name")} ${transaction && getValue(seller.user, "last_name")}`;
      })}`,
    },
    {
      id: "3",
      label: "Property value",
      value: `${transaction && getValue(transaction, "asking_price")}`,
    },
    {
      id: "4",
      label: "Tenure",
      value: `${transaction?.property_id.tenure}`,
    },
    {
      id: "5",
      value: `${handleMapsUrl(transaction)}`,
      isLink: true,
    },
  ];

  return (
    <div className="mb-[32px] md:mb-[16px] md:mr-[16px] pb-[24px] max-w-[548px] md:min-w-[320px]">
      <Card
        titleColor="brand-heavy-teal-100"
        titleBackgroundColor="brand-heavy-warmth-50"
        title="Check property details"
      >
        <>
          {checkPropertyDetailsSection.map((section) => (
            <div key={section.id}>
              <div className="my-[12px]">
                {section.label && (
                  <Typography
                    type="h2"
                    variant="xl"
                    weight="bold"
                    className="text-brand-heavy-teal-100 my-[8px]"
                  >
                    {section.label}
                  </Typography>
                )}
                {section.isLink ? (
                  <Typography
                    type="h4"
                    variant="h4"
                    weight="bold"
                    className="text-brand-heavy-teal-100 underline cursor-pointer"
                  >
                    <a href={section.value} target="_blank" rel="noreferrer">
                      See in the map
                    </a>
                  </Typography>
                ) : (
                  <Typography
                    type="p"
                    variant="base"
                    weight="regular"
                    className="text-brand-heavy-teal-100"
                  >
                    {section.value}
                  </Typography>
                )}
              </div>
            </div>
          ))}
        </>
      </Card>
    </div>
  );
};
