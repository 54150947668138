import { useMemo } from "react";

import { formatFields } from "@shared/utils";
import { Card, Typography } from "@pongo";
import { GiftorSchemaState } from "@schema";

import { FormattedFields } from "./AdditionalPerson.types";
import { QuestionSet } from "./QuestionSet";
import { FieldType } from "../../constants";

interface AdditionalGiftorSummaryCardProps {
  values: GiftorSchemaState;
  onDeletePerson: (personId: string) => void;
}

export const AdditionalGiftorSummaryCard = ({
  values,
  onDeletePerson,
}: AdditionalGiftorSummaryCardProps) => {
  const formattedFields: FormattedFields = useMemo(
    () => formatFields(values),
    [values],
  );

  const filteredFields = useMemo(
    () =>
      formattedFields.otherFields.filter(
        (field) =>
          field.answer &&
          [
            FieldType.Email,
            FieldType.MobileNumber,
            FieldType.PostCode,
          ].includes(field.question as FieldType),
      ),
    [formattedFields],
  );

  return (
    <div className="flex flex-col mb-[32px] md:mb-[16px] md:mr-[16px] pb-[24px] max-w-[548px] md:min-w-[320px]">
      <Card
        titleColor="brand-heavy-teal-100"
        titleBackgroundColor="brand-heavy-warmth-50"
        title="Giftor"
        titleRightContent={
          <button
            className="underline cursor-pointer text-brand-heavy-teal-75"
            onClick={() => onDeletePerson(values.personId)}
            type="button"
          >
            <Typography
              variant="base"
              weight="regular"
              type="span"
              className="text-brand-heavy-teal-75"
            >
              Delete
            </Typography>
          </button>
        }
      >
        <div>
          <QuestionSet question="Amount" answer={formattedFields.amount} />

          {formattedFields.legalName && (
            <QuestionSet
              question="Legal Name"
              answer={formattedFields.legalName}
            />
          )}

          {filteredFields.map(({ question, answer }, index) => (
            <div key={index}>
              <QuestionSet question={question} answer={answer} />
            </div>
          ))}

          <QuestionSet
            question="Relationship"
            answer={formattedFields.relationship}
          />
        </div>
      </Card>
    </div>
  );
};
