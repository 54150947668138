import { ReactElement } from "react";
import Typography from "../../foundation/Typography/Typography";

export interface CardProps {
  title?: string | ReactElement;
  titleColor?: string;
  titleBackgroundColor?: string;
  titleRightContent?: ReactElement;
  backgroundColour?: string;
  children?: ReactElement;
  containerClasses?: string;
}

export const Card = ({
  title,
  children,
  titleColor = "ui-white-100",
  titleBackgroundColor = "brand-heavy-teal-100",
  titleRightContent,
  backgroundColour,
  containerClasses,
}: CardProps) => {
  return (
    <div
      className={`${backgroundColour ? backgroundColour : "bg-ui-white-100"} shadow-questionType rounded-xl border border-ui-black-10 ${containerClasses}`}
    >
      {title && (
        <div
          className={`h-[64px] bg-${titleBackgroundColor} flex items-center justify-between rounded-t-lg px-[32px]`}
        >
          {typeof title === "string" ? (
            <Typography
              type="h4"
              variant="h4"
              weight="bold"
              className={`text-${titleColor}`}
            >
              {title}
            </Typography>
          ) : (
            <>{title}</>
          )}

          {titleRightContent && <div>{titleRightContent}</div>}
        </div>
      )}
      <div className="p-[24px] pl-[32px] pr-[32px]">{children}</div>
    </div>
  );
};
