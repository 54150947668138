import { useMemo } from "react";
import { Field, FieldProps, useFormikContext } from "formik";

import { Typography, ErrorText, Icons, Select } from "@pongo";
import { Roles } from "@shared/types";
import { isSeller } from "@shared/utils";

import {
  checkboxConfigWithNoIntroductionToBuyerAndAdditionalInfoEmails,
  checkboxConfigWithNoIntroductionToSellerEmail,
  convertRoleToLowerCase,
  generateLawyerGroupOptions,
  getFieldValue,
} from "./utils";
import { LawyerGroupFormState } from "./AssignSolicitorsBlock";
import { LawyerGroup } from "../../../types";
import { useTransactionContext } from "../../context/TransactionProvider";
import { CheckboxList } from "./CheckboxList";
import {
  checkboxIndividualTransactionConfig,
  checkboxTransactionsGroupConfig,
} from "./fields";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";

interface AssignSolicitorsProps {
  lawyerGroups: LawyerGroup[];
  isInputDisabled: boolean;
  displayConfirmCard: boolean;
  isSelectedSellerSolicitorNonPanel: boolean;
  isTransactionsGroup: boolean;
}

export const AssignSolicitors = ({
  lawyerGroups,
  isInputDisabled,
  displayConfirmCard,
  isSelectedSellerSolicitorNonPanel,
  isTransactionsGroup,
}: AssignSolicitorsProps) => {
  const { errors } = useFormikContext<LawyerGroupFormState>();
  const { transaction } = useTransactionContext();
  const { transactionsGroup } = useTransactionsGroupContext();

  const lawyerGroupOptions = useMemo(
    () => generateLawyerGroupOptions(lawyerGroups),
    [lawyerGroups],
  );

  const checkboxes = useMemo(() => {
    const isPurchaseIntentActive = transaction?.purchase_intent?.some(
      (intent) => intent?.is_active,
    );

    let baseConfig = isTransactionsGroup
      ? checkboxTransactionsGroupConfig
      : checkboxIndividualTransactionConfig;

    if (isSelectedSellerSolicitorNonPanel) {
      baseConfig = checkboxConfigWithNoIntroductionToSellerEmail(baseConfig);
    }

    if (!isPurchaseIntentActive && !isTransactionsGroup) {
      baseConfig =
        checkboxConfigWithNoIntroductionToBuyerAndAdditionalInfoEmails(
          baseConfig,
        );
    }

    if (isTransactionsGroup && !transactionsGroup.hasActivePurchaseIntent) {
      baseConfig =
        checkboxConfigWithNoIntroductionToBuyerAndAdditionalInfoEmails(
          baseConfig,
        );
    }

    return baseConfig;
  }, [
    isSelectedSellerSolicitorNonPanel,
    isTransactionsGroup,
    transaction?.purchase_intent,
    transactionsGroup.hasActivePurchaseIntent,
  ]);

  return (
    <>
      {[Roles.Seller, Roles.Buyer].map((role) => {
        const lowerCaseRole = convertRoleToLowerCase(role);
        const roleLawyerGroup = `${lowerCaseRole}_lawyer_group`;
        const error = errors[roleLawyerGroup as keyof LawyerGroupFormState];

        return (
          <div key={role}>
            <Typography
              type="h2"
              variant="xl"
              weight="bold"
              className="text-brand-heavy-teal-100 my-[16px]"
            >
              {`${role} Solicitor`}
            </Typography>
            <div className="flex flex-col w-auto">
              <div
                className="flex flex-col w-[100%]"
                data-testid="assign-lawyer-group"
              >
                {/* Dropdown */}
                <Field name={roleLawyerGroup}>
                  {({ field }: FieldProps) => {
                    return (
                      <Select
                        {...field}
                        data-testid={roleLawyerGroup}
                        name={roleLawyerGroup}
                        id={roleLawyerGroup}
                        options={lawyerGroupOptions}
                        variant={
                          isInputDisabled || displayConfirmCard
                            ? "disabled"
                            : error
                              ? "error"
                              : "enabled"
                        }
                        value={getFieldValue(
                          transaction?.lawyer_group,
                          lowerCaseRole,
                          field.value,
                        )}
                      />
                    );
                  }}
                </Field>
                <ErrorText>{error}</ErrorText>

                {/* Emails checkboxes */}
                <CheckboxList config={checkboxes} role={role} />
              </div>
              {isSeller(role) && (
                <div className="mx-auto my-[12px]">
                  <Icons iconName="DoubleArrow" colour="complement" size="24" />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
