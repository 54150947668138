import { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";

import { AddressState } from "@schema";
import Typography from "@/foundation/Typography/Typography";
import ErrorText from "@/foundation/Typography/ErrorText";
import { Button } from "@/components/Button/Button";

import { PostcodeLookup } from "./PostcodeLookup";
import { ManualAddress } from "./ManualAddress";
import { AddressDisplayCard } from "./AddressDisplayCard";
import { FieldSet } from "../../FieldSet/FieldSet";
import { useTransactionFormStore } from "../../../../store/TransactionForm/transactionForm";

interface AddressInputsProps {
  namespace: string;
  addressTitle: string;
  addressDescription?: string;
  cardAddressTitle: string;
  showUseCorrespondenceAddress?: boolean;
}

export const AddressInputs = ({
  namespace,
  addressTitle,
  addressDescription,
  cardAddressTitle,
  showUseCorrespondenceAddress = false,
}: AddressInputsProps) => {
  const { transaction } = useTransactionFormStore();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, helpers] = useField<AddressState>(namespace);
  const { errors, touched } = useFormikContext();

  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isManualAddressOpen, setIsManualAddressOpen] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(true);

  const onSetAddress = (addressValues: AddressState) => {
    helpers.setValue(addressValues);

    setIsEditingAddress(false);
  };

  useEffect(() => {
    if (
      !isPageLoaded &&
      field.value.line_1 &&
      field.value.postcode &&
      field.value.post_town &&
      field.value.country
    ) {
      setIsEditingAddress(false);
    }

    setIsPageLoaded(true);
  }, [field, isPageLoaded, setIsEditingAddress]);

  const handleSaveAddress = () => {
    setIsManualAddressOpen(false);
    setIsEditingAddress(false);
  };

  const handleUseCorrespondenceAddress = () => {
    const { primarySeller } = transaction;

    helpers.setValue(primarySeller.correspondenceAddress);
    setIsEditingAddress(false);
  };

  const addressHasErrors = () => {
    const hasBeenTouched = touched[namespace as keyof typeof touched];

    const hasErrors =
      errors[`${namespace}.line_1` as keyof typeof errors] ||
      errors[`${namespace}.line_2` as keyof typeof errors] ||
      errors[`${namespace}.line_3` as keyof typeof errors] ||
      errors[`${namespace}.post_town` as keyof typeof errors] ||
      errors[`${namespace}.country` as keyof typeof errors] ||
      errors[`${namespace}.postcode` as keyof typeof errors];

    return hasBeenTouched && hasErrors;
  };

  const clearInvisibleFieldsOnAddress = () => {
    const addressValues: AddressState = {
      line_1: field.value.line_1,
      line_2: field.value.line_2,
      line_3: field.value.line_3,
      post_town: field.value.post_town,
      country: field.value.country,
      postcode: field.value.postcode,
    };

    helpers.setValue(addressValues);
  };

  return (
    <FieldSet legend={addressTitle} description={addressDescription}>
      {isEditingAddress ? (
        <>
          <PostcodeLookup handleSetAddress={onSetAddress} />

          <ManualAddress
            namespace={namespace}
            isManualAddressOpen={isManualAddressOpen}
            setIsManualAddressOpen={setIsManualAddressOpen}
          />
          {isManualAddressOpen && (
            <Button
              size="2xl"
              variant="primary"
              disabled={
                !field.value.line_1 ||
                !field.value.post_town ||
                !field.value.country ||
                !field.value.postcode ||
                addressHasErrors()
              }
              type="button"
              onClick={handleSaveAddress}
              className="w-full md:w-[200px] h-[64px] my-[16px]"
            >
              Save address
            </Button>
          )}

          {showUseCorrespondenceAddress && (
            <div className="mb-[16px]">
              <button
                className="underline cursor-pointer text-brand-heavy-teal-75"
                onClick={handleUseCorrespondenceAddress}
                type="button"
              >
                <Typography
                  variant="base"
                  weight="regular"
                  type="span"
                  className="text-brand-heavy-teal-75"
                >
                  Use correspondence address
                </Typography>
              </button>
            </div>
          )}
        </>
      ) : (
        <AddressDisplayCard
          onEditAddress={() => {
            setIsEditingAddress(true);
            setIsManualAddressOpen(true);
            clearInvisibleFieldsOnAddress();
          }}
          cardAddressTitle={cardAddressTitle}
          address={field.value}
        />
      )}
      <div className="h-[16px]">
        <ErrorText>
          {addressHasErrors() &&
            "There is an error with the address, please correct it before continuing."}
        </ErrorText>
      </div>
    </FieldSet>
  );
};
