import { useNavigate } from "react-router-dom";

import { SellerPropertyDetailsState } from "@schema";

import { SellerAboutThePropertyForm } from "../../components/Form/DetailsForm/AboutThePropertyForm/SellerAboutThePropertyForm";
import { sellAboutTheProperty } from "../../components/Header/allStepperOptions";
import { PageTemplate } from "../../components/Form/PageTemplate/PageTemplate";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";

export const SellerAboutTheProperty = () => {
  const { updateTransaction, transaction } = useTransactionFormStore();
  const navigate = useNavigate();

  const handleSubmit = (values: SellerPropertyDetailsState): void => {
    const primarySeller = {
      ...transaction.primarySeller,
      customerType: values.customerType,
    };

    updateTransaction({
      property: values,
      primarySeller,
    });

    navigate("/sell/terms-and-conditions");
  };

  return (
    <PageTemplate
      title="About the property"
      stepperOptions={sellAboutTheProperty}
    >
      <SellerAboutThePropertyForm
        state={transaction.property}
        onSubmit={handleSubmit}
      />
    </PageTemplate>
  );
};
