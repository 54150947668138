import { useEffect, useState } from "react";

import { useTransactionContext } from "../../context/TransactionProvider";
import { ThirdfortSellersEstateAgentRequestsChecks } from "./ThirdfortSellersEstateAgentRequestsChecks";
import { ThirdfortTable } from "./ThirdfortTable";
import { ThirdfortBlock } from "../TransactionBlocks/ThirdfortBlock";

/**
 *
 * @returns current component for Thirdfort tabs.
 * Based on the date of the transaction, it will render the appropriate version of the ThirdfortSellers component.
 */
export const ThirdfortSellersV2 = () => {
  const { transaction } = useTransactionContext();

  const [
    estateAgentRequestsThirdfortChecks,
    setEstateAgentRequestsThirdfortChecks,
  ] = useState(false);

  useEffect(() => {
    if (transaction) {
      setEstateAgentRequestsThirdfortChecks(
        !!transaction?.estate_agent?.thirdfort?.requestsSellerThirdfortChecks,
      );
    }
  }, [transaction]);

  return (
    <>
      {estateAgentRequestsThirdfortChecks ? (
        <ThirdfortSellersEstateAgentRequestsChecks />
      ) : (
        <ThirdfortTable
          cardTitle="Sellers"
          persons={transaction!.sellers.map((s) => ({
            first_name: s.user.first_name,
            last_name: s.user.last_name,
            email: s.user.email,
            phone_number:
              s.user.phone_number || s.user.profile?.phone_number || "",
            person_type: "seller",
          }))}
        />
      )}
    </>
  );
};

/**
 *
 * @returns legacy component for Thirdfort tabs.
 * Based on the date of the transaction, it will render the appropriate version of the ThirdfortSellers component.
 */
export const ThirdfortSellersV1 = () => {
  const { transaction } = useTransactionContext();

  return (
    transaction &&
    transaction.sellers.map((seller, index) => (
      <ThirdfortBlock
        key={`thirdfort-seller-${index}`}
        title={`Seller ${index + 1}`}
        address={transaction.property_id.address}
        profile={seller.user}
        solicitorEmail={
          transaction.lawyer_group?.seller.solicitor.email.toLowerCase() || ""
        }
        leftLabel={`Seller ${index + 1} of ${transaction.sellers.length}`}
      />
    ))
  );
};

interface ThirdfortSellersProps {
  showV2Sellers: boolean;
}

export const ThirdfortSellers = ({ showV2Sellers }: ThirdfortSellersProps) => (
  <>
    {showV2Sellers && <ThirdfortSellersV2 />}
    <ThirdfortSellersV1 />
  </>
);
