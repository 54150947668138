import { useEffect, useState } from "react";

import Typography from "@/foundation/Typography/Typography";
import Icons from "@/foundation/Icons/Icons";
import { FullPageLoader } from "../FullPageLoader/FullPageLoader";
import { ApiController } from "../../service/ApiController";

import { TransactionResponse, Role } from "@shared/types";
import { formatCurrency } from "@shared/utils";
import { useAuthStore } from "../../store/auth/auth";

interface TransactionCardProps {
  transaction?: TransactionResponse;
  handleResumeTransaction: (id: string) => void | undefined;
  type: Role;
}

export const TransactionCard = ({
  transaction,
  handleResumeTransaction,
  type,
}: TransactionCardProps) => {
  const { userData } = useAuthStore();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    if (!transaction) return;

    const fetchEvents = async () => {
      const events = await ApiController.fetchTransactionEvents(
        transaction._id,
      );
      const hasEvent = (eventName: string) =>
        events.some((e) => e.event_name === eventName);

      const isCompleteStatus =
        type === "sell"
          ? hasEvent("payment_successful") || hasEvent("referral_code_applied")
          : hasEvent("purchase_intent_success_email_sent");

      setIsComplete((prevStatus) =>
        prevStatus !== isCompleteStatus ? isCompleteStatus : prevStatus,
      );
    };

    fetchEvents();

    const currentPurchaseIntent = transaction.purchase_intent.find((intent) =>
      intent?.buyers?.some((buyer) => buyer?.user?._id === userData?._id),
    );

    const price =
      type === "sell"
        ? transaction.asking_price
        : currentPurchaseIntent?.agreed_price || 0;

    setPrice((prevPrice) => (prevPrice !== price ? price : prevPrice));
  }, [transaction, type, userData]);

  if (!transaction) {
    return <FullPageLoader />;
  }

  const date = new Date(transaction.createdAt).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const { line_1, line_2, county, post_town, postcode } =
    transaction.property_id.address;
  const firstLineAddress = [line_1, line_2].filter(Boolean).join(", ");
  const secondLineAddress = [county, post_town, postcode]
    .filter(Boolean)
    .join(", ");

  const bgStyles = isComplete
    ? "bg-brand-medium-sand-50"
    : "bg-brand-medium-sand-50 hover:bg-brand-medium-sand-75";

  const handleContinue = () => {
    if (isComplete) return;
    handleResumeTransaction(transaction._id);
  };

  return (
    <div
      key={transaction._id}
      className={`flex justify-between ${bgStyles} rounded-xl overflow-hidden border border-brand-heavy-warmth-100 mb-[16px]`}
    >
      <button
        type="button"
        onClick={handleContinue}
        className="text-left flex flex-col p-[24px] w-full"
        disabled={isComplete}
      >
        <div className="flex justify-between w-full">
          <div
            className={`py-[4px] px-[8px] ${isComplete ? "bg-brand-electric-100" : "bg-brand-warmth-100"} rounded-[3px] mb-[12px]`}
          >
            <Typography
              type="p"
              variant="xs"
              weight="semi-bold"
              className="text-brand-heavy-teal-100"
            >
              {isComplete ? "Onboarding Complete" : "In Progress"}
            </Typography>
          </div>

          <Typography
            type="p"
            variant="sm"
            weight="regular"
            className="text-brand-heavy-teal-100"
          >
            {date}
          </Typography>
        </div>

        <Typography
          type="h3"
          variant="xl"
          weight="bold"
          className="text-brand-heavy-teal-100 mb-[6px]"
        >
          {firstLineAddress}
        </Typography>

        <Typography
          type="p"
          variant="base"
          weight="semi-bold"
          className="text-brand-heavy-teal-100 mb-[24px]"
        >
          {secondLineAddress}
        </Typography>

        <div className="flex flex-row justify-between items-end w-full">
          <Typography
            type="p"
            variant="base"
            weight="semi-bold"
            className="text-brand-heavy-teal-100"
          >
            {formatCurrency(price, true, true)}
          </Typography>

          {isComplete ? (
            <Icons iconName="Checkmark" size="24" colour="#01323C" />
          ) : (
            <Icons iconName="ArrowRight" size="24" colour="#01323C" />
          )}
        </div>
      </button>
    </div>
  );
};
