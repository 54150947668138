import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { PersonalDetailsWithAddressState } from "@schema";

import { AboutYouForm } from "../../components/Form/DetailsForm/AboutYouForm/AboutYouForm";
import { PageTemplate } from "../../components/Form/PageTemplate/PageTemplate";
import { useAuthStore } from "../../store/auth/auth";
import { ErrorMessageWithEmail } from "../../components/Form/DetailsForm/AddressLookup/ErrorMessageWithEmail";
import { sellAboutYou } from "../../components/Header/allStepperOptions";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";

export const BuyerAboutYou = () => {
  const { purchaseIntent, updatePurchaseIntent } = usePurchaseIntentFormStore();
  const currentUser = useAuthStore();
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (!currentUser.userData?.email) {
      Sentry.captureException("No primary buyer or user email found");
      setError(
        "There was an error with loading the form details, please contact support at",
      );
    }
  }, [currentUser.userData?.email]);

  const handleFormSubmit = (values: PersonalDetailsWithAddressState) => {
    updatePurchaseIntent({
      ...purchaseIntent,
      primaryBuyer: values,
    });

    navigate("/buy/about-the-property");
  };

  return (
    <PageTemplate title="About you" stepperOptions={sellAboutYou}>
      {error ? (
        <ErrorMessageWithEmail propertyErrorMessage={error} />
      ) : (
        <AboutYouForm
          state={{
            ...purchaseIntent.primaryBuyer,
            email: currentUser.userData?.email || "",
          }}
          onSubmit={handleFormSubmit}
        />
      )}
    </PageTemplate>
  );
};
