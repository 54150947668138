export interface CheckboxConfigGroup {
  Seller: CheckboxConfig[];
  Buyer: CheckboxConfig[];
}

interface CheckboxConfig {
  fieldName: string;
  fieldLabel: string;
  tooltipText: string;
  disabled: boolean;
}

export const checkboxBuyerOptions: CheckboxConfig[] = [
  {
    disabled: false,
    fieldName: "introductionEmailToBuyerChecked",
    fieldLabel: "Send introduction email to buyer",
    tooltipText:
      "Send an email to the buyer introducing the buyer's solicitor when a buyer successfully onboards.",
  },
  {
    disabled: false,
    fieldName: "furtherDetailsEmailToBuyerSolicitorChecked",
    fieldLabel: "Send further details email to buyer's solicitor",
    tooltipText:
      "Send new instructions about the seller, purchase intent, and the seller's solicitor to the buyer's solicitor.",
  },
  {
    disabled: false,
    fieldName: "additionalInfoEmailToBuyerSolicitorChecked",
    fieldLabel: "Send additional info email to buyer's solicitor",
    tooltipText:
      "Send additional information regarding the buyer to the buyer's solicitor when a buyer successfully onboards.",
  },
];

export const checkboxIndividualTransactionConfig: CheckboxConfigGroup = {
  Seller: [
    {
      fieldName: "introductionEmailToSellerChecked",
      fieldLabel: "Send introduction email to seller",
      tooltipText:
        "Send an email to the seller introducing the seller's solicitor.",
      disabled: false,
    },
    {
      disabled: false,
      fieldName: "furtherDetailsEmailToSellerSolicitorChecked",
      fieldLabel: "Send further details email to seller's solicitor",
      tooltipText:
        "Send information about the seller, transaction, and the buyer's solicitor to the seller's solicitor.",
    },
  ],
  Buyer: checkboxBuyerOptions,
};

export const checkboxTransactionsGroupConfig: CheckboxConfigGroup = {
  Seller: [
    {
      disabled: false,
      fieldName: "introductionEmailToSellerChecked",
      fieldLabel: "Send introduction email to seller",
      tooltipText:
        "Send an email to the contact/representative of seller (company) introducing the seller's solicitor.",
    },
    {
      disabled: false,
      fieldName: "plotPlanRequestToSellerChecked",
      fieldLabel: "Send plot plan request email to seller",
      tooltipText:
        "Send email to the contact/representative of seller (company) with plot plan request in order to raise searches.",
    },
    {
      disabled: false,
      fieldName: "furtherDetailsEmailToSellerSolicitorChecked",
      fieldLabel: "Send further details email to seller's solicitor",
      tooltipText:
        "Send information about the seller (company), contat/representative, transactions, and the buyer's solicitor to the seller's solicitor.",
    },
  ],
  Buyer: checkboxBuyerOptions,
};
