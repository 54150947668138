import { FormikErrors } from "formik";
import { SellerPropertyDetailsState } from "@schema";

const requiresAdditionalSeller = ({
  customerType,
  isAnotherSellerSelected,
  additionalSellers,
}: SellerPropertyDetailsState): boolean =>
  customerType === "representative" &&
  isAnotherSellerSelected !== undefined &&
  additionalSellers.length < 1;

const requiresTenureDetails = ({
  tenureOption,
  tenureDetails,
}: SellerPropertyDetailsState): boolean =>
  tenureOption === "Other / Not Sure" && !tenureDetails;

export const customSellerPropertyDetailsValidation = (
  values: SellerPropertyDetailsState,
): Partial<FormikErrors<SellerPropertyDetailsState>> => {
  const errors: Partial<FormikErrors<SellerPropertyDetailsState>> = {};

  if (requiresAdditionalSeller(values)) {
    errors.isAnotherSellerSelected =
      "If you are acting on behalf of the seller, please select yes and add the seller’s details";
  }

  if (requiresTenureDetails(values)) {
    errors.tenureDetails = "Please provide details";
  }

  return errors;
};
