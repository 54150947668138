import { create } from "zustand";
import { persist } from "zustand/middleware";

import {
  BuyerPropertyDetailsState,
  PersonalDetailsWithAddressState,
} from "@schema";
import {
  initialBuyerPropertyDetailsStateObj,
  initialPersonalDetailsStateObj,
} from "../initialDetails";
import { Property } from "../../components/Form/DetailsForm/AddressLookup/types";

export interface PurchaseIntentFormState {
  _id: string;
  primaryBuyer: PersonalDetailsWithAddressState;
  property: BuyerPropertyDetailsState;
  isActive: boolean;
  schemaVersion: number;
}

type State = {
  purchaseIntent: PurchaseIntentFormState;
  updatePurchaseIntent: (
    updatedPurchaseIntent: Partial<PurchaseIntentFormState>,
  ) => void;
  resetPurchaseIntent: () => void;
};

export const initialPurchaseIntentStateObj: PurchaseIntentFormState = {
  _id: "",
  primaryBuyer: { ...initialPersonalDetailsStateObj, primaryCustomer: true },
  property: initialBuyerPropertyDetailsStateObj,
  isActive: false,
  schemaVersion: 1,
};

export const initialPropertyStateObj: Property = {
  address: {
    line_1: "",
    line_2: "",
    line_3: "",
    post_town: "",
    country: "",
    postcode: "",
    _id: "",
  },
  propertyId: "",
  _id: "",
  tenure: "",
};

export const usePurchaseIntentFormStore = create<State>()(
  persist(
    (set) => ({
      purchaseIntent: initialPurchaseIntentStateObj,

      updatePurchaseIntent: (
        updatedPurchaseIntent: Partial<PurchaseIntentFormState>,
      ) => {
        set((state) => ({
          purchaseIntent: {
            ...state.purchaseIntent,
            ...updatedPurchaseIntent,
          },
          updatePurchaseIntent: state.updatePurchaseIntent,
        }));
      },
      resetPurchaseIntent: () => {
        set(() => ({
          purchaseIntent: initialPurchaseIntentStateObj,
        }));
      },
    }),
    {
      name: "purchaseIntentForm",
    },
  ),
);
