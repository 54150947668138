import { useFormikContext } from "formik";

import { BuyerPropertyDetailsState } from "@schema";
import { ErrorText } from "@pongo";

import { AddBuyerPropertyAddress } from "./AddBuyerPropertyAddress";
import { AddressDisplayCard } from "./AddressDisplayCard";
import { FieldSet } from "../../FieldSet/FieldSet";

export const BuyerPropertyAddress = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<BuyerPropertyDetailsState>();

  return (
    <FieldSet
      legend="Sale property address"
      description="This is the address of the property that you intend to purchase."
    >
      {values.propertyAddress ? (
        <AddressDisplayCard
          cardAddressTitle="Property address"
          onEditAddress={() => {
            setFieldValue("propertyAddress", undefined);
            setFieldValue("property_id", "");
          }}
          address={values.propertyAddress}
        />
      ) : (
        <AddBuyerPropertyAddress />
      )}

      <div className="h-[16px]">
        <ErrorText>{touched.property_id && errors.property_id}</ErrorText>
      </div>
    </FieldSet>
  );
};
