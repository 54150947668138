import axios from "axios";
import { Link } from "react-router-dom";

import { Typography } from "@pongo";
import { useTabletMediaQuery } from "../../../mediaQueries";
import OrangePropelrLogo from "@assets/logos/orange-propelr-logo.svg";

export const Footer = () => {
  const isTablet = useTabletMediaQuery();

  const triggerErrorFrontend = () => {
    console.log("triggerErrorFrontend");
    throw new Error("triggerErrorFrontend");
  };

  const triggerErrorBackend = () => {
    console.log("triggerErrorBackend");
    console.log(`/api/__testerror`);
    axios
      .get(`/api/__testerror`)
      .then((response) => {
        console.log("GET response:", response.data);
      })
      .catch((error) => {
        console.error("GET error:", error);
      });
  };

  return (
    <div className="pt-[72px] pb-[24px] px-[36px] bg-brand-heavy-teal-100">
      <div className="flex flex-col justify-between items-center">
        <a href="/">
          <img src={OrangePropelrLogo} className="mb-[24px]" />
        </a>

        <span
          style={{
            width: 20,
            height: 20,
            bottom: 0,
            left: 0,
            position: "fixed",
          }}
          onClick={triggerErrorFrontend}
        />
        <span
          style={{
            width: 20,
            height: 20,
            bottom: 0,
            right: 0,
            position: "fixed",
          }}
          onClick={triggerErrorBackend}
        />

        <Typography
          className={`text-brand-heavy-teal-50 mx-auto text-center ${isTablet ? "mb-[75px]" : "mb-[24px]"}`}
          type="p"
          variant="xs"
          weight="regular"
        >
          71 Queen Victoria Street, London, United Kingdom, EC4V 4BE© 2024
          Propelr Property Limited
        </Typography>

        <div className="flex flex-row justify-between">
          <Link to={`/cookie-policy`}>
            <Typography
              className="text-brand-heavy-teal-50 px-[8px]"
              type="span"
              variant="xs"
              weight="regular"
            >
              Cookie Policy
            </Typography>
          </Link>

          <Link to={`/privacy-policy`}>
            <Typography
              className="text-brand-heavy-teal-50 px-[8px]"
              type="span"
              variant="xs"
              weight="regular"
            >
              Privacy Policy
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};
