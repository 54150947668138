import { Typography } from "@pongo";

import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { AdminTitle } from "../AdminPageTemplate/AdminTitle";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { TransactionsGroupBlock } from "./TransactionsGroupBlock";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";

interface TransactionsGroupProps {
  title: string;
}

export const TransactionsGroup = ({ title }: TransactionsGroupProps) => {
  const { loading, error } = useTransactionsGroupContext();

  return (
    <>
      <Breadcrumbs
        root={{
          name: "Transactions Groups",
          url: "/admin/transactions-groups",
        }}
        stem={"Add Transactions Group"}
      />

      <AdminTitle title={title} />

      {loading && <FullPageLoader />}

      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}

      <TransactionsGroupBlock />
    </>
  );
};
