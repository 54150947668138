import Typography from "../../foundation/Typography/Typography";

export interface LozengeProps {
  children: React.ReactNode;
  backgroundColor?: string;
  fontColor?: string;
}

export const Lozenge = ({
  children,
  backgroundColor = "bg-brand-heavy-warmth-100",
  fontColor = "text-brand-heavy-teal-100",
}: LozengeProps) => (
  <div className="shrink-0">
    <Typography
      weight="bold"
      className={`flex py-[4px] px-[16px] rounded-full ${backgroundColor} ${fontColor}`}
    >
      {children}
    </Typography>
  </div>
);
