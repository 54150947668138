import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";
import { paths } from "@shared/types.d";

export type GetMonthlyTransactionCountRequest =
  paths["/api/transaction/total/{month}/{year}"]["get"]["parameters"]["path"];

export const getMonthlyTransactionCount = async ({
  month,
  year,
}: GetMonthlyTransactionCountRequest): Promise<number> => {
  try {
    const response = await api.get(`transaction/total/${month}/${year}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Transaction count not found");
  }
};
