import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";

import {
  PersonalDetailsWithAddressSchema,
  PersonalDetailsWithAddressState,
} from "@schema";

import { Footer } from "../../../Footer/Footer";
import { BasicDetailsForm } from "../BasicDetailsForm/BasicDetailsForm";
import { AddressInputs } from "../AddressLookup/AddressInputs.tsx";
import { useLoginStore } from "../../../../store/Login/login";
import { useAuthStore } from "../../../../store/auth/auth";
import { FullPageLoader } from "../../../../pages/FullPageLoader/FullPageLoader";

interface AboutYouFormProps {
  state: PersonalDetailsWithAddressState;
  onSubmit: (values: PersonalDetailsWithAddressState) => void;
}

const sellerAddressDescription =
  "Tell us where we can reach you by post. This may be different from the sale address.";
const buyerAddressDescription = "Tell us where we can reach you by post.";

export const AboutYouForm = ({ state, onSubmit }: AboutYouFormProps) => {
  const navigate = useNavigate();
  const { role } = useLoginStore();
  const { userData } = useAuthStore();
  const [initialFormState, setInitialFormState] =
    useState<PersonalDetailsWithAddressState>(state);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userData) {
      setIsLoading(true);
      setInitialFormState((prevState) => ({
        ...prevState,
        firstName: userData.first_name || "",
        middleName: userData.middle_name || "",
        lastName: userData.last_name || "",
      }));
      setIsLoading(false);
    }
  }, [userData]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Formik
      initialValues={initialFormState}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validate={withZodSchema(PersonalDetailsWithAddressSchema)}
      validateOnMount
    >
      {(formik) => {
        return (
          <form onSubmit={formik.handleSubmit}>
            <BasicDetailsForm isAdditionalSeller={false} />

            <AddressInputs
              namespace="correspondenceAddress"
              addressTitle="Correspondence address"
              addressDescription={
                role === "sell"
                  ? sellerAddressDescription
                  : buyerAddressDescription
              }
              cardAddressTitle="Correspondence address"
            />

            <Footer
              handleBack={() => navigate(`/${role}/welcome`)}
              isValid={formik.isValid}
            />
          </form>
        );
      }}
    </Formik>
  );
};
