import { useEffect, useState } from "react";

import { Lozenge, Typography, Card } from "@pongo";
import { formatCurrency } from "@shared/utils";

import {
  Seller,
  Giftors,
  PurchaseIntent,
} from "../../pages/TransactionDetails/types";
import { BuyerSellerBlock } from "../BuyerSellerBlock";
import { SignificantDatesCard } from "../SignificantDatesCard/SignificantDatesCard";
import { Table, TableProps } from "../Table/Table";
import { useTransactionContext } from "../../context/TransactionProvider";
import { getFundingValues } from "./utils";

interface PurchaseIntentTabProps {
  purchaseIntent: PurchaseIntent;
  purchaseIntentIndex: number;
  onUpdatedPurchaseIntent: (newPurchaseIntent: PurchaseIntent) => void;
}

const PurchaseIntentCard = ({
  purchaseIntent,
  purchaseIntentIndex,
  onUpdatedPurchaseIntent,
}: PurchaseIntentTabProps) => {
  const [buyers, setBuyers] = useState<Array<Seller & { giftors?: Giftors[] }>>(
    [],
  );
  const [purchaseIntentInfo, setPurchaseIntentInfo] = useState<TableProps[]>(
    [],
  );

  useEffect(() => {
    const {
      buyers,
      agreed_price,
      mortgage_required_for_purchase,
      mortgage_provider,
      funding,
      other_funding_comments,
      purchase_dependent_on_sale,
      ownership_type,
      first_time_buyer,
      is_using_foreign_currency,
    } = purchaseIntent;

    setBuyers(buyers);
    setPurchaseIntentInfo([
      {
        label: "Agreed Price",
        value: formatCurrency(agreed_price, true, true),
      },
      {
        label: "Is Using Foreign Currency",
        value: is_using_foreign_currency ? "Yes" : "No",
      },
      {
        label: "Mortgage Required",
        value: mortgage_required_for_purchase,
      },
      {
        label: "Mortgage Provider",
        value: mortgage_provider,
      },
      { label: "Funding", value: getFundingValues(funding) },
      {
        label: "Funding Arrangement Comments",
        value: other_funding_comments ? other_funding_comments : "None",
      },
      {
        label: "Purchase Dependent on Sale",
        value: purchase_dependent_on_sale.toString(),
      },
      {
        label: "Ownership Type",
        value: ownership_type,
      },
      {
        label: "First Time Buyer",
        value: first_time_buyer.toString(),
      },
    ]);
  }, [purchaseIntent]);

  return (
    <>
      <Card
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
        title={
          <div className="flex items-center space-x-3">
            <Typography
              type="h4"
              variant="h4"
              weight="bold"
              className="text-brand-heavy-teal-100"
            >
              {`Purchase Intent ${purchaseIntentIndex}`}
            </Typography>
            {purchaseIntent.is_active !== undefined && (
              <Lozenge
                fontColor="text-ui-white-100"
                backgroundColor={
                  purchaseIntent.is_active
                    ? "bg-ui-success-100"
                    : "bg-ui-error-100"
                }
              >
                {purchaseIntent.is_active ? "Active" : "Inactive"}
              </Lozenge>
            )}
          </div>
        }
      >
        <>
          <div className="flex flex-wrap w-full gap-[24px]">
            <div className="flex-1">
              <Card
                title="Purchase Intent Info"
                titleBackgroundColor="brand-heavy-warmth-25"
                titleColor="brand-heavy-teal-100"
              >
                <>
                  {purchaseIntentInfo.map(({ value, label }) => (
                    <Table
                      key={`purchase-intent-${purchaseIntentIndex}-${label}`}
                      value={value}
                      label={label}
                    />
                  ))}
                </>
              </Card>
            </div>

            <div className="w-[350px]">
              <SignificantDatesCard
                id={purchaseIntent._id}
                significantDates={purchaseIntent.significant_dates || {}}
                onSiginificantDatesUpdated={onUpdatedPurchaseIntent}
              />
            </div>
          </div>

          <Typography
            type="h3"
            variant="h3"
            weight="bold"
            className="text-brand-heavy-teal-100 font-display w-[505px] my-[32px]"
          >
            Buyers
          </Typography>

          <div className="flex flex-col space-y-5">
            {buyers.map((buyer, index) => (
              <BuyerSellerBlock key={`seller-${index}`} buyerSeller={buyer} />
            ))}
          </div>
        </>
      </Card>
    </>
  );
};

export const PurchaseIntentTab = () => {
  const { transaction, setTransaction } = useTransactionContext();

  return transaction?.purchase_intent
    .sort((purchaseIntent) => (purchaseIntent.is_active ? -1 : 1))
    .map((purchaseIntent, index) => (
      <PurchaseIntentCard
        key={`purchase-intent-${index}`}
        purchaseIntentIndex={index + 1}
        purchaseIntent={purchaseIntent}
        onUpdatedPurchaseIntent={(newPurchaseIntent) => {
          const updatedPurchaseIntents = transaction.purchase_intent.map(
            (pi, i) => (i === index ? newPurchaseIntent : pi),
          );

          if (setTransaction) {
            setTransaction({
              ...transaction,
              purchase_intent: updatedPurchaseIntents,
            });
          }
        }}
      />
    ));
};
