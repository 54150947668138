import { Typography } from "@pongo";

interface AdminNavigationLabelProps {
  label: string;
}

export const AdminNavigationLabel = ({ label }: AdminNavigationLabelProps) => (
  <Typography
    variant="xs"
    className="hidden md:group-hover:block absolute left-0 top-[30px] bg-brand-heavy-teal-100 text-ui-white-100 px-[8px] py-[4px] rounded-[4px] transition-all duration-300 ease-in-out text-nowrap"
  >
    {label}
  </Typography>
);
