// This function transforms the Purchase Intent from the database into
// the state value used in the Transaction local storage object

import { PurchaseIntentFormState } from "../store/PurchaseIntentForm/purchaseIntentForm";
import { transformTransactionBuyerState } from "./transformTransactionBuyerState";
import { transformPurchaseIntentPropertyState } from "./transformPurchaseIntentPropertyState";
import { PurchaseIntentResponse, PurchaseIntentBuyer } from "@shared/types";
import { Address } from "../admin/pages/UserDetails/types";

export const transformPurchaseIntentForLocalStorage = (
  purchaseIntent: PurchaseIntentResponse,
  propertyAddress: Address,
): PurchaseIntentFormState => {
  const buyers: PurchaseIntentBuyer[] = purchaseIntent.buyers;

  return {
    _id: purchaseIntent._id,
    primaryBuyer: transformTransactionBuyerState(buyers),
    property: transformPurchaseIntentPropertyState(
      purchaseIntent,
      propertyAddress,
    ),
    isActive: purchaseIntent.is_active,
    schemaVersion: purchaseIntent.schema_version,
  };
};
