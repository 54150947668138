import { FieldArray, Formik, FormikErrors, FormikProps } from "formik";
import { withZodSchema } from "formik-validator-zod";

import { ManualTransactionsState, manualTransactionsSchema } from "@schema";
import { Card } from "@pongo";

import { AddPropertyDetails } from "./AddPropertyDetails";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { initialTransactionsGroupStateObj } from "./initialDetails";

interface PropertyDetailsBlockProps<T> {
  submitTrigger: (trigger: () => void) => void;
  onError: (errors: FormikErrors<T> | undefined) => void;
}

export const PropertyDetailsBlock = <T extends object>({
  submitTrigger,
  onError,
}: PropertyDetailsBlockProps<T>) => {
  const {
    manualTransactions,
    addManualTransaction,
    removeManualTransaction,
    resetManualTransactions,
  } = useTransactionsGroupContext();

  const setFieldsTouched = (
    formik: FormikProps<ManualTransactionsState>,
    index: number,
  ) => {
    const fields = [
      `manualTransactions.${index}.property.askingPrice`,
      `manualTransactions.${index}.property.propertyAddress.line_1`,
      `manualTransactions.${index}.property.propertyAddress.line_2`,
      `manualTransactions.${index}.property.propertyAddress.line_3`,
      `manualTransactions.${index}.property.propertyAddress.post_town`,
      `manualTransactions.${index}.property.propertyAddress.country`,
      `manualTransactions.${index}.property.propertyAddress.postcode`,
    ];

    fields.forEach((field) => {
      formik.setFieldTouched(field, true);
    });
  };

  return (
    <Formik
      initialValues={
        manualTransactions
          ? { manualTransactions: manualTransactions }
          : initialTransactionsGroupStateObj
      }
      onSubmit={() => {}}
      validate={withZodSchema(manualTransactionsSchema)}
      validateOnMount
    >
      {(formik) => {
        if (submitTrigger) {
          submitTrigger(async () => {
            try {
              const validationErrors = await formik.validateForm();

              if (Object.keys(validationErrors).length > 0) {
                onError(validationErrors as FormikErrors<T>);
                Object.keys(validationErrors).forEach((key) => {
                  formik.setFieldTouched(key, true);
                });
              } else {
                onError(undefined);
                await formik.submitForm();
              }
            } catch {
              onError({} as FormikErrors<T>);
            }
          });
        }
        return (
          <Card
            title="Property Details"
            backgroundColour="bg-brand-heavy-warmth-25"
            titleRightContent={
              <button
                className={`text-ui-white-100 ${manualTransactions.length <= 1 && "hidden"}`}
                onClick={() => {
                  formik.setFieldValue("manualTransactions", []);
                  resetManualTransactions();
                }}
              >
                Remove all
              </button>
            }
          >
            <FieldArray name="manualTransactions">
              {({ remove, push }) => (
                <>
                  {manualTransactions?.map((_, index) => (
                    <div key={index}>
                      <AddPropertyDetails
                        transactionIndex={index}
                        onDuplicatePrevious={async () => {
                          const containErrors = await formik.validateForm();
                          setFieldsTouched(formik, index);
                          if (!Object.keys(containErrors).length) {
                            const newTx = {
                              ...formik.values.manualTransactions[index],
                              events: [],
                              orders: [],
                            };
                            push(newTx);

                            addManualTransaction(newTx, index);
                          }
                        }}
                        onRemove={() => {
                          remove(index);
                          removeManualTransaction(index);
                        }}
                      />
                      {index !== manualTransactions.length - 1 && (
                        <hr className="flex border-t-2 border-brand-heavy-warmth-75 my-[20px]" />
                      )}
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
          </Card>
        );
      }}
    </Formik>
  );
};
