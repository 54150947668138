import { z } from "zod";

import { customerType, tenureOption } from "@shared/types";

import { AddressSchema } from "./AddressSchema";
import { PersonalDetailsWithAddressSchema } from "./PersonalDetailsSchema";
import { requiredField } from "./helpers";
import { FEATURE_FLAGS } from "../constants";

export const SellerPropertyDetailsSchema = z
  .object({
    _id: z.string().optional(),
    propertyAddress: AddressSchema,
    askingPrice: z.string().refine(
      (price) => {
        const numericPrice = parseFloat(price.replace(/,/g, "")); // Remove commas and convert to float
        return !isNaN(numericPrice) && numericPrice >= 99000;
      },
      {
        message: "Asking price must be higher than £99,000",
      },
    ),
    tenureOption: requiredField(z.enum(tenureOption)),
    tenureDetails: z.string().optional(),
    hasMortgage: requiredField(z.boolean()),
    onwardPurchase: requiredField(z.boolean()),
    customerType: requiredField(z.enum(customerType)),
    additionalSellers: z.array(PersonalDetailsWithAddressSchema),
    isAnotherSellerSelected: requiredField(z.boolean()),
    ...(FEATURE_FLAGS.isUsingForeignCurrency && {
      isUsingForeignCurrency: requiredField(z.boolean()),
    }),
  })
  .describe(
    "Validates seller's property details at the 'About the property' page.",
  );
