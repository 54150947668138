import { useState } from "react";

import { Card, Typography } from "@pongo";

import { Table } from "../Table/Table";
import { LawyerGroupThirdfortModal } from "./modals/LawyerGroupThirdfortModal";
import { LawyerGroup } from "../../../types";

interface LawyerGroupThirdfortProps {
  lawyerGroup: LawyerGroup;
  onUpdated: (newGroup: LawyerGroup) => void;
}

export const LawyerGroupThirdfort = ({
  lawyerGroup,
  onUpdated,
}: LawyerGroupThirdfortProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Card
        title="Thirdfort Recipients"
        titleRightContent={
          <button
            className="text-brand-heavy-warmth-25 underline"
            onClick={() => setModalIsOpen(true)}
          >
            Edit
          </button>
        }
      >
        <>
          <Typography>
            The following emails will be used to send to estate agents who
            receive Thirdfort checks for sellers.
          </Typography>
          <div className="mt-[16px]">
            {lawyerGroup.thirdfortRecipientEmails?.length ? (
              lawyerGroup.thirdfortRecipientEmails.map((email, index) => (
                <Table
                  key={`email-${index}`}
                  label={`Email ${index + 1}`}
                  value={email}
                />
              ))
            ) : (
              <Typography weight="bold">
                No emails added, please add an email
              </Typography>
            )}
          </div>
        </>
      </Card>
      {modalIsOpen && (
        <LawyerGroupThirdfortModal
          onClose={() => setModalIsOpen(false)}
          lawyerGroup={lawyerGroup}
          onUpdated={(lawyerGroup) => {
            setModalIsOpen(false);
            onUpdated(lawyerGroup);
          }}
        />
      )}
    </>
  );
};
