import { formatCamelCaseString } from "@shared/utils";

import { Funding } from "../../pages/TransactionDetails/types";

const formatFundingEntry = (
  key: string,
  value: string | boolean,
): string | null => {
  if (key === "gifts") {
    if (value === "no") return null; // Exclude "gifts" if its value is "no"
    if (value === "not_sure") return "Gifts (Maybe)";
  }
  return formatCamelCaseString(key);
};

export const getFundingValues = (values: Funding): string =>
  Object.entries(values)
    .filter(([key, value]) => key !== "_id" && Boolean(value))
    .map(([key, value]) => formatFundingEntry(key, value))
    .filter(Boolean) // Remove any null or falsy values
    .join(", ");
