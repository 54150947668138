import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { withZodSchema } from "formik-validator-zod";
import { Formik } from "formik";

import {
  SellerPropertyDetailsSchema,
  SellerPropertyDetailsState,
} from "@schema";
import { Button } from "@pongo";
import { Roles } from "@shared/types";

import { Footer } from "../../../Footer/Footer";
import { relationshipToSaleOptions } from "../Fields";
import { TypeOfProperty } from "../TypeOfProperty/TypeOfProperty";
import { Price } from "../Price/Price";
import { RadioButtonQuestion } from "../RadioButtonQuestion/RadioButtonQuestion";
import { CustomerType } from "../CustomerType/CustomerType";
import { SellerPropertyAddress } from "../AddressLookup/SellerPropertyAddress";
import { AdditionalPersonSummaryCard } from "../../../AdditionalPerson/AdditionalPersonSummaryCard";
import { AddSellerModal } from "../AddPersonModal/AddSellerModal";
import { ForeignCurrency } from "../ForeignCurrency/ForeignCurrency";
import { customSellerPropertyDetailsValidation } from "./utils";
import { ForeignCurrencyCard } from "../ForeignCurrency/ForeignCurrencyCard";
import { FEATURE_FLAGS } from "../../../../constants";

interface SellerAboutThePropertyFormProps {
  state: SellerPropertyDetailsState;
  onSubmit: (values: SellerPropertyDetailsState) => void;
}

export const SellerAboutThePropertyForm = ({
  state,
  onSubmit,
}: SellerAboutThePropertyFormProps) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Formik
        initialValues={state}
        onSubmit={(values) => onSubmit(values)}
        validateOnMount
        validate={(values) => {
          let errors = {};
          const zodErrors = withZodSchema(SellerPropertyDetailsSchema)(values);
          const customErrors = customSellerPropertyDetailsValidation(values);

          errors = { ...zodErrors, ...customErrors };
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleSubmit,
          isValid,
          validateForm,
        }) => (
          <form className="flex flex-col items-start" onSubmit={handleSubmit}>
            <SellerPropertyAddress />

            <Price
              priceTitle="Asking price"
              priceDescription="If the asking price is not yet confirmed please enter an reasonable estimate."
              typeOfPrice="askingPrice"
              placeholder="Asking price"
            />

            <TypeOfProperty />

            <RadioButtonQuestion
              title="Existing mortgage"
              description="Is there an existing mortgage on the property?"
              label="hasMortgage"
              options={["Yes", "No"]}
              selectedRadioButtonQuestionOption={
                values.hasMortgage === undefined
                  ? undefined
                  : values.hasMortgage
                    ? "Yes"
                    : "No"
              }
              setSelectedRadioButtonQuestionOption={(newValue) => {
                setFieldValue("hasMortgage", newValue === "Yes" ? true : false);
              }}
              radioButtonQuestionError={
                touched.hasMortgage ? errors.hasMortgage : ""
              }
            />

            <RadioButtonQuestion
              title="Future purchase"
              description="Are you planning to buy another property following this sale?"
              label="onwardPurchase"
              options={["Yes", "No"]}
              selectedRadioButtonQuestionOption={
                values.onwardPurchase === undefined
                  ? undefined
                  : values.onwardPurchase
                    ? "Yes"
                    : "No"
              }
              setSelectedRadioButtonQuestionOption={(newValue) => {
                setFieldValue(
                  "onwardPurchase",
                  newValue === "Yes" ? true : false,
                );
              }}
              radioButtonQuestionError={
                touched.onwardPurchase ? errors.onwardPurchase : ""
              }
            />

            {FEATURE_FLAGS.isUsingForeignCurrency && (
              <>
                <ForeignCurrency description="As part of your planned sale or wider finances, will you be moving money internationally?" />

                <ForeignCurrencyCard
                  isUsingForeignCurrency={values.isUsingForeignCurrency}
                />
              </>
            )}

            <CustomerType
              relationshipToSaleOptions={relationshipToSaleOptions}
              selectedOption={values.customerType || ""}
              setSelectedOption={(newValue) => {
                setFieldValue("customerType", newValue);
              }}
              customerTypeError={
                touched.customerType ? errors.customerType : ""
              }
            />

            {/* Another Sellers */}

            <RadioButtonQuestion
              title="Other sellers or joint mortgage holders"
              description="If you not the only seller or are acting on behalf of a seller, select yes and add the additional seller’s details."
              label="additionalSeller"
              options={["Yes", "No"]}
              selectedRadioButtonQuestionOption={
                values.isAnotherSellerSelected === undefined
                  ? undefined
                  : values.isAnotherSellerSelected
                    ? "Yes"
                    : "No"
              }
              setSelectedRadioButtonQuestionOption={(newValue) => {
                validateForm();
                setFieldValue("isAnotherSellerSelected", newValue === "Yes");

                setTimeout(() =>
                  setFieldTouched("isAnotherSellerSelected", true),
                );

                if (newValue === "Yes") {
                  setIsModalOpen(true);
                }
                if (newValue === "No") {
                  setFieldValue("additionalSellers", []);
                }
              }}
              radioButtonQuestionError={
                touched.isAnotherSellerSelected
                  ? errors.isAnotherSellerSelected
                  : ""
              }
            />

            {isModalOpen && (
              <AddSellerModal
                onCancel={() => {
                  setIsModalOpen(false);
                  setFieldValue("isAnotherSellerSelected", undefined);
                }}
                onSaved={(newSeller) => {
                  setIsModalOpen(false);
                  setFieldValue("additionalSellers", [
                    ...values.additionalSellers,
                    newSeller,
                  ]);
                }}
              />
            )}

            {/* All Sellers Cards */}

            <div className="flex md:flex-row md:flex-wrap flex-col w-full mt-[40px]">
              {values.additionalSellers.length > 0 &&
                values.additionalSellers.map((seller, index) => (
                  <div key={`additional-seller-summary-${index}`}>
                    <AdditionalPersonSummaryCard
                      values={seller}
                      customer={Roles.Seller}
                      onDeletePerson={(id) => {
                        if (values.additionalSellers.length <= 1) {
                          setFieldValue("isAnotherSellerSelected", undefined);
                        }
                        setFieldValue(
                          "additionalSellers",
                          values.additionalSellers.filter(
                            (seller) => seller.personId !== id,
                          ),
                        );
                      }}
                    />
                  </div>
                ))}
            </div>

            {values.additionalSellers.length > 0 && (
              <div className="flex w-full md:w-[400px]">
                <Button
                  variant="primary"
                  className="w-[320px]"
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                  icon="PlusWhite"
                >
                  Add another seller
                </Button>
              </div>
            )}

            <Footer
              isValid={isValid}
              handleBack={() => navigate("/sell/about-you")}
            />
          </form>
        )}
      </Formik>
    </>
  );
};
