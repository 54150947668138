import { Typography } from "@pongo";

import { ContactForm } from "./ContactForm";
import { Banner } from "../Banner/Banner";
import { useTabletMediaQuery } from "../../../mediaQueries";

import Couple from "../../../assets/couple-teal-background.png";

export const ContactFormBanner = () => {
  const isTablet = useTabletMediaQuery();

  return (
    <Banner backgroundColour="bg-brand-heavy-warmth-100" imageUrl={Couple}>
      <div
        id="contact"
        className="w-full flex flex-col items-center lg:flex-row"
      >
        <div className="w-full max-w-[375px] shrink-0 px-[16px] lg:ml-[24px] md:mr-[50px]">
          <Typography
            className="font-display text-brand-heavy-teal-100 mb-[30px] text-center md:text-left"
            type="h2"
            variant={isTablet ? "h1" : "h2"}
            weight="semi-bold"
          >
            Find out how easy we can make it
          </Typography>

          <Typography
            className="text-brand-heavy-teal-100 mb-[40px] text-center md:text-left"
            type="p"
            variant="lg"
            weight="regular"
          >
            Talk to our friendly and knowledgeable <br /> team today.
          </Typography>
        </div>

        <ContactForm />
      </div>
    </Banner>
  );
};
