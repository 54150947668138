import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@pongo";

import { Banner } from "../Banner/Banner";

export const EstateAgentBanner = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/partner-sign-up");
  };

  return (
    <Banner backgroundColour="bg-brand-medium-sand-50" imageUrl="">
      <div
        id="contact"
        className="w-full flex flex-col items-center justify-between lg:space-x-5 lg:flex-row py-[24px]"
      >
        <Typography
          className="relative lg:left-[-50px] font-display text-brand-heavy-teal-100 mb-[30px] lg:mb-0 lg:text-left text-nowrap"
          type="h2"
          variant="h2"
          weight="semi-bold"
        >
          Estate Agent?
        </Typography>

        <Typography
          className="text-brand-heavy-teal-100 mb-[20px] lg:mb-0 text-center lg:text-left w-full max-w-[375px]"
          type="p"
          variant="lg"
          weight="regular"
        >
          Find out more about how Propelr can <br />
          help your business.
        </Typography>

        <Button
          variant="primary"
          type="submit"
          onClick={handleClick}
          className="w-full max-w-[362px]"
          disabled={false}
        >
          Sign-up as a partner
        </Button>
      </div>
    </Banner>
  );
};
